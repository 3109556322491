<!--
 * @Author: juan.liu
 * @LastEditors: juan.liu
 * @Date: 2022-11-15 09:45:19
 * @LastEditTime: 2022-11-15 13:39:02
 * @FilePath: /scrm-h5/src/views/cusIntention/components/itemField.vue
-->
<template>
  <van-field
        v-model="inputValue"
        v-bind="$attrs"
        :show-word-limit="showWordLimit"
        @focus="showWordLimit=true"
        @blur="showWordLimit=false"
        @input="handleInput"
      />
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {
      showWordLimit: false
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set() {

      }
    }
  },

  mounted() {
    document.title = ''
  },

  methods: {
    handleInput(value) {
      // console.log(value)
      this.$emit('input', value)
    }
  }
}

</script>
<style lang='less' scoped>
</style>
