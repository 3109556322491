<!--
 * @Author: ke.xue
 * @Date: 2021-12-17 11:23:01
 * @LastEditors: ke.xue
 * @LastEditTime: 2022-01-11 20:12:12
 * @FilePath: \qw-scrm-h5\components\clue\form.vue
 * @Description: 自定义表单组件
-->
<template>
  <van-popup
    :value="show"
    :position="position"
    :overlay="overlay"
    :close-on-click-overlay="closeOverlay"
    :style="{ width, height }"
    :safe-area-inset-bottom="safeAreaInsetBottom"
    @click-overlay="closePopup">
    <div v-if="show" id="clueForm" class="clueForm">
      <div class="formContent">
        <component
          :is='item.type'
          v-for='item in formJsonList'
          :key='item.submitKey'
          v-model='item.defaultValue'
          :form-json='item'
        >
        </component>
        <slot></slot>
      </div>
      <div class='formBottom'>
        <div class='butBox'>
          <van-button plain type='info' block @click='formReset'>重置</van-button>
        </div>
        <div class='butBox'>
          <van-button type='info' block @click='formConfirm'>确认</van-button>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import ClueRadio from './radio.vue'
import ClueCheckbox from './checkbox.vue'
import ClueCheckboxNew from './checkboxNew.vue'
import ClueCalendar from './calendar.vue'
import CluePicker from './picker.vue'
import SelectPage from './selectPage.vue'
import { dataType } from '@/utils/utils'

export default {
  name: 'ClueForm',
  components: {
    ClueRadio,
    ClueCheckbox,
    ClueCalendar,
    ClueCheckboxNew,
    CluePicker,
    SelectPage
  },
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    formJson: {
      type: Array,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    position: {
      type: String,
      default: 'center'
    },
    overlay: {
      type: Boolean,
      default: true
    },
    safeAreaInsetBottom: {
      type: Boolean,
      default: true
    },
    closeOverlay: {
      type: [Boolean, Number, String],
      default: false
    }
  },
  data() {
    return {
      radioValue: '初始化',
      formJsonList: [],
      formTypeObj: {
        ClueRadio: '',
        ClueCheckbox: [],
        ClueCalendar: [],
        ClueCheckboxNew: [],
        SelectPage: []
      }
    }
  },
  watch: {
    show: {
      handler(nVal) {
        if (nVal) {
          // this.formJsonList = JSON.parse(JSON.stringify(this.formJson))
          const { formJson } = this
          const arr = []
          formJson.map(item => {
            const obj = {}
            Object.assign(obj, item)
            arr.push(obj)
          })
          this.formJsonList = arr
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // this.formJsonList = JSON.parse(JSON.stringify(this.formJson))
  },
  methods: {
    closePopup() {
      this.$emit('change', false)
    },
    formReset() {
      const params = {}; const defaultValue = []
      this.formJsonList = this.formJsonList.map(item => {
        if (!item.disabled) {
          item.defaultValue = this.formTypeObj[item.type]
        }
        if (item.type === 'ClueRadio' || item.type === 'ClueCheckbox') {
          item.screenValue = item.screenValue.map(i => {
            i.active = false
            return i
          })
        }
        defaultValue.push(item.defaultValue)
        params[item.submitKey] = item.defaultValue
        return item
      })
      // this.$emit('change', false)
      // const timer = setTimeout(() => {
      //   this.$emit('change', true)
      //   clearTimeout(timer)
      // }, 100)
      this.$emit('reset', params, defaultValue)
    },
    formConfirm() {
      const params = {}; const defaultValue = []
      let isFilter = false
      this.formJsonList.forEach(item => {
        if (!isFilter) {
          if (item.defaultValue) {
            if (dataType(item.defaultValue, 'array') && item.defaultValue.length) {
              isFilter = true
            }
            else if (dataType(item.defaultValue, 'object') && Object.keys(item.defaultValue).length) {
              isFilter = true
            }
            else if (dataType(item.defaultValue, 'string')) {
              isFilter = true
            }
          }
          else if (dataType(item.defaultValue, 'number')) {
            isFilter = true
          }
        }
        params[item.submitKey] = item.defaultValue
        defaultValue.push(item.defaultValue)
      })
      this.$emit('confirm', params, { defaultValue, isFilter })
    }
  }
}
</script>

<style lang='less' scoped>
.clueForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .formContent {
    flex: 1;
    overflow-y: auto;
    padding: 0 16px 16px;
    /deep/ .dFlex {
      display: flex;
    }
    /deep/ .dFlexJcsb {
      justify-content: space-between;
    }
    /deep/ .formBox {
      .formLabel {
        margin: 16px 0 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #262626;
        &.formRequired::before {
          content: '*';
          color: #FF5257;
          font-size: 14px;
        }
      }
      .screenValueBox {
        display: flex;
        flex-wrap: wrap;
        .screenValueItem {
          margin: 12px 12px 0 0;
          padding: 0 16px;
          font-size: 12px;
          line-height: 24px;
          background: #EEE;
          color: #666;
          &.active {
            background: rgba(0, 136, 255, 0.1);
            color: #0088FF;
          }
        }
      }
    }
  }

  .formBottom {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    height: 56px;

    .butBox {
      width: 160px;
      height: 40px;
    }

    /deep/ .van-button {
      box-sizing: border-box;
      border-radius: 4px;
      height: 40px;
      line-height: 38px;
    }
  }
}
</style>
<style lang="less" scoped>
.myPopup {
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 9999;
  background: #fff;
}
</style>

