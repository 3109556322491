<template>
  <div>
    <div class='tools'>
      群标签设置（同一标签组内标均为【或】关系,最多支持10组标签）
    </div>
    <template>
      <div v-for='(item,index) in tagData.tagList' :key='index' class='content'>
        <div class='nav'>
          <span v-if='index!== 0'>且</span>
          <div class='title'> 第{{ index + 1 }}组</div>
          <div class='btn'>
            <el-button size='mini' icon='el-icon-plus' type='primary' @click='addTagsGroup(index)'>新增标签组</el-button>
            <el-button size='mini' icon='el-icon-delete' type='primary' :disabled='tagData.tagList.length === 1' @click='removeTagsGroup(index)'>删除标签组
            </el-button>
          </div>
        </div>
        <div class='tagList'>
          <div class='tag-left'>
            <div v-for='(t,tIndex) in item.list' :key='t.tagId' class='tag' @click='removeTag(item,tIndex)'>
              <span>{{ t.tagName }}</span>
              <i class='el-icon-close icon'></i>
            </div>
            <div class='addTag' @click='addTags(index)'>
              <i class='el-icon-plus add'></i>
              <span>添加标签</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="editformBtn" :style="{ position: 'absolute',left: 0, bottom: 0,right: 0,zIndex: 9,borderTop: '1px solid #e9e9e9',padding: '10px 20px', background: '#fff',textAlign: 'right',}">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="saveDialog">确 定</el-button>
    </div>
    <BaseDialog :visible.sync="showTagAdd" title="群标签" width="500px" destroy-on-close :container-style="{ height: 'calc(100% - 60px)', overflow: 'auto', paddingBottom: '30px' }">
      <addTage :check-tag-data="checkTagData" :padding-bottom='0' @close="showTagAdd=false" @save="addTageSave"></addTage>
    </BaseDialog>
  </div>
</template>
<script>
// import _ from 'lodash'
import addTage from '@/views/cusGroupOperateSop/components/addTage.vue'
export default {
  name: 'TagsChoose',
  components: { addTage },
  props: {
    tagsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tagData: {
        tagList: [
          {
            'order': 0,
            'list': []
          }
        ]
      },
      showTagAdd: false,
      checkTagData: [] // 已添加的标签ids,用于选择标签时高亮已有标签
    }
  },
  created() {
    this.tagData.tagList = this.tagsList.length ? this.tagsList : this.tagData.tagList
  },
  methods: {
    addTagsGroup(index) {
      if (this.tagData.tagList.length <= 9) {
        this.tagData.tagList.splice(index + 1, 0, {
          'list': [],
          'order': Number(index) + 1
        })
      }
      else {
        this.$message.error('标签组最多新增10组')
      }
    },
    removeTagsGroup(index) {
      if (this.tagData.tagList === 1) return
      this.$confirm(`您确定要删除标签组${index + 1}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async() => {
          this.tagData.tagList.splice(index, 1)
        })
        .catch(() => {
          this.$message.info('已取消')
        })
    },
    addTags(index) {
      this.showTagAdd = true
      this.sourceIndex = index
      this.checkTagData = this.tagData.tagList[this.sourceIndex].list
    },
    removeTag(tagItem, index) {
      tagItem.list.splice(index, 1)
    },
    addTageSave(list) {
      this.showTagAdd = false
      const obj = this.tagData.tagList[this.sourceIndex]
      list.forEach(item => {
        if (!obj.list.some(t => t.tagId === item.tagId)) {
          if (obj.list.length <= 19) obj.list.push(item)
          else this.$message.error('每组最多添加20个标签')
        }
      })
    },
    closeDialog() {
      this.$emit('close')
    },
    saveDialog() {
      this.$emit('save', this.tagData.tagList)
    }
  }
}
</script>
<style lang="less" scoped>
.tools {
  width: 100%;
  padding: 0 0 16px 0;
  color:#333333;
}

.content {
  padding-bottom: 20px;

  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
    }

    .btn {
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #287de1;
      padding: 4px 10px;
    }
  }

  .tagList {
    display: flex;
    flex-direction: row;
    border: 1px solid #f6f6f6;

    .tag-left {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .tag {
        width: auto;
        background: #f3f8fe;
        font-size: 12px;
        font-weight: 400;
        color: #287de1;
        padding: 5px 10px;
        margin: 5px 10px;
        display: flex;
        align-items: center;
        height: 15px;
        cursor: pointer;

        .icon {
          margin-left: 5px;
        }
      }

      .addTag {
        color: #287de1;
        background: #fff;
        border: 1px solid #287de1;
        //background: #f5f5f5;
        //border: 1px solid #e8e8e8;
        font-size: 12px;
        //color: #999999;
        padding: 5px 10px;
        margin: 5px 10px;
        font-weight: 400;
        display: flex;
        align-items: center;
        height: 15px;
        cursor: pointer;

        .add {
          margin-right: 5px;
        }
      }
    }
  }
}

.card {
  width: 100%;
  background-color: #fff;
  padding: 5px;
  display: grid;
  align-content: center;
  grid-template-columns: 40px auto;
  .avar {
    width: 36px;
    height: 36px;
    border-radius: 5px;
  }
  .cont {
    display: grid;
    align-content: space-between;
    margin-left: 8px;
    font-size: 12px;
    .groupName {
      font-size: 14px;
    }
  }
}
.editformBtn {
  text-align: right;
}
</style>
