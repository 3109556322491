<!-- 客户群 SOP -->
<template>
  <div>
    <TopFilter :list="topFilterList" label-width="100" :cols="3" @filterChange="changeHandle"></TopFilter>
    <FilterTable ref="table" height="auto" :columns="columns" :params="fetchParams" :fetchapi="fetchApi" datakey="dataList" :is-select-column="false" :is-server-sorter="true" :on-columns-change="(columns) => (this.columns = columns)">
      <template slot="controls" slot-scope="">
        <el-button type="primary" title="新增" icon="el-icon-plus" @click="addData">新 增</el-button>
      </template>
    </FilterTable>

    <BaseDialog :visible.sync="showAddNew" :title="dialogTitle" width="1250px" destroy-on-close :container-style="{ height: 'calc(100% - 60px)', overflow: 'auto', paddingBottom: '30px' }">
      <EditForm v-if="showAddNew" :is-detail="isDetail" :edit-data="editData" @close="closeDialog" @save="save" />
    </BaseDialog>
  </div>
</template>

<script>
import { cus_add, cus_get_detail_by_id, cus_get_list, cus_update, getdict } from '@/api/modules/cusGroupOperateSop.js'
import EditForm from '@/views/cusGroupOperateSop/editForm.vue'
import { confirmAction } from '@/utils/utils'

export default {
  name: 'Index',
  components: { EditForm },
  data() {
    return {
      topFilterList: this.createTopFilters(),
      fetchParams: { paramData: {}},
      fetchApi: cus_get_list,
      columns: [],
      // 弹层
      showAddNew: false,
      dialogTitle: '',
      editData: {},
      isDetail: false
    }
  },
  watch: {
    showAddNew(val) {
      if (!val) this.editData = {}
    }
  },
  created() {
    this.topFilterList = this.createTopFilters()
    this.columns = this.createTableColumns()
    this.getDictList()
  },
  methods: {
    createTopFilters() {
      return [
        {
          type: 'SELECT',
          label: 'SOP类型',
          fieldName: 'sopType',
          placeholder: 'SOP类型',
          initialValue: '',
          itemList: [
            { text: '客户运营', value: 1 },
            { text: '内容运营', value: 2 },
            // { text: '朋友圈', value: 3 },
            { value: 4, text: '活动运营' }
          ]
        },
        {
          type: 'INPUT',
          label: 'SOP名称',
          fieldName: 'name',
          initialValue: '',
          placeholder: 'SOP名称'
        },
        {
          type: 'SELECT',
          label: '状态',
          fieldName: 'validityPeriodStatus',
          placeholder: '状态',
          initialValue: '',
          itemList: [
            { text: '全部', value: '' },
            { text: '进行中', value: 0 },
            { text: '已关闭', value: -1 },
            { text: '未开始', value: 1 },
            { text: '已过期', value: 2 }
          ]
        },
        {
          type: 'INPUT',
          label: '客户群',
          fieldName: 'chatGroupParam',
          initialValue: '',
          placeholder: '客户群'
        },
        {
          type: 'RANGE_DATE',
          label: '更新时间:',
          hidden: false,
          clearable: false,
          fieldName: 'startUpdatedTime|endUpdatedTime',
          dateType: 'datetimerange',
          valueFormat: 'YYYY-MM-DD',
          initialValue: ['', ''],
          placeholder: ['开始时间', '结束时间'],
          change: (value) => { }
        }
        // {
        //   type: 'SELECT',
        //   label: '执行方',
        //   fieldName: 'executor',
        //   placeholder: '执行方',
        //   initialValue: '',
        //   itemList: []
        // }
      ]
    },
    createTableColumns() {
      return [
        {
          title: 'SOP类型',
          dataIndex: 'sopType',
          sorter: false,
          filter: false,
          render: (props) => {
            switch (props.row.sopType) {
              case 1:
                return <span>客户运营</span>
              case 2:
                return <span>内容运营</span>
              case 3:
                return <span>朋友圈</span>
              case 4:
                return <span>活动运营</span>
              default:
                ''
            }
          }
        },
        {
          title: 'SOP名称',
          dataIndex: 'name',
          sorter: false,
          filter: false
        },
        {
          title: 'SOP执行描述',
          dataIndex: 'description',
          sorter: false,
          filter: false
        },
        {
          title: '状态',
          dataIndex: 'validityPeriodStatus',
          sorter: false,
          filter: false,
          render: (props) => {
            return <span>{props.row.validityPeriodStatus === -1 ? '已关闭' : props.row.validityPeriodStatus === 0 ? '进行中' : props.row.validityPeriodStatus === 1 ? '未开始' : props.row.validityPeriodStatus === 2 ? '已过期' : ''}</span>
          }
        },
        {
          title: '执行周期',
          dataIndex: 'startDate',
          sorter: false,
          filter: false,
          render: (props) => {
            return (
              <span>
                {props.row.startDate} 至 {props.row.endDate}
              </span>
            )
          }
        },
        {
          title: '客户群',
          dataIndex: 'chatGroupNames',
          sorter: false,
          filter: false,
          render: (props) => {
            return <span>{props.row.chatGroupNames ? props.row.chatGroupNames.toString() : ''}</span>
          }
        },
        // {
        //   title: '发送时间',
        //   dataIndex: '',
        //   sorter: false,
        //   filter: false
        // },
        {
          title: '执行方',
          dataIndex: 'executor',
          sorter: false,
          filter: false
        },
        {
          title: '更新时间',
          dataIndex: 'updatedTime',
          sorter: false,
          filter: false
        },

        {
          title: '操作',
          dataIndex: 'column-action',
          width: 200,
          fixed: 'right',
          render: (props) => {
            const { row } = props
            if (row.validityPeriodStatus === 2) {
              return (
                <div>
                  <el-button size='mini' type='text' onClick={() => this.edit(props.row)}>
                    编辑
                  </el-button>
                  <el-button size='mini' type='text' onClick={() => this.details(props.row)}>
                    详情
                  </el-button>
                </div>
              )
            }
            else {
              return (
                <div>
                  <el-button size='mini' type='text' onClick={() => this.edit(props.row)}>
                    编辑
                  </el-button>
                  <el-button size='mini' type='text' onClick={() => this.statusChange(props.row)}>
                    {props.row.status === 0 ? '关闭' : '开启'}
                  </el-button>
                  <el-button size='mini' type='text' onClick={() => this.details(props.row)}>
                    详情
                  </el-button>
                </div>
              )
            }
          }
        }
      ]
    },
    changeHandle(val) {
      console.log('搜索的参数：', val)
      this.fetchParams = Object.assign({}, this.fetchParams, { paramData: val })
    },
    // 关闭
    closeDialog(type = 'close') {
      this.showAddNew = false
    },
    async getDictList() {
      const data = await getdict({ type: 'executor' })
      data.data.map((item) => {
        item.text = item.name
        item.value = item.code
      })
      this.topFilterList[4].itemList = data.data
    },

    async save(val) {
      if (val.id) {
        await cus_update(val)
      }
      else {
        await cus_add(val)
      }
      val.loading = false
      this.changeHandle({})
      this.closeDialog()
    },
    addData() {
      console.log('新增')
      this.showAddNew = true
      this.dialogTitle = '新增'
      this.isDetail = false
    },
    statusChange(item) {
      const status = item.status === 0 ? -1 : 0
      const obj = getObj(status)
      confirmAction(obj.text, obj.title)
        .then(async(res) => {
          await cus_update({
            id: item.id,
            status
          }).then((data) => {
            item.status = status
          })
        })
        .catch(() => { })

      function getObj(status) {
        let obj = {}
        if (status === 0) {
          obj = {
            text: '确认是否启用',
            title: '开启'
          }
        }
        else {
          obj = {
            text: '确认是否关闭',
            title: '关闭'
          }
        }
        return obj
      }
    },
    async edit(row) {
      const data = await cus_get_detail_by_id(row.id)
      this.editData = data.data
      this.showAddNew = true
      this.isDetail = false
      this.dialogTitle = '编辑'
    },
    async details(row) {
      const data = await cus_get_detail_by_id(row.id)
      this.editData = data.data
      this.showAddNew = true
      this.isDetail = true
      this.dialogTitle = '详情'
    }
  }
}
</script>

<style scoped>
</style>
