import axios from 'axios'

const service = axios.create({
  baseURL: '', // api 的 base_url
  timeout: 500000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // const res = response.data
    return response
  }, error => {
    return Promise.reject(error)
  })

export default service
