
export const contentConfig = (type) => {
  const content = {
    // 企业群发 个人群发
    // 支持最多1个文案及最多9个附件类型（图片、图文、小程序、视频、文件）
    1: {
      'content_type_001': {
        maxNum: 1,
        nowNum: 0
      },
      'content_type_002': {
        maxNum: 9,
        nowNum: 0
      },
      'content_type_003': {
        maxNum: 9,
        nowNum: 0
      },
      'content_type_004': {
        maxNum: 9,
        nowNum: 0
      },
      'content_type_005': {
        maxNum: 9,
        nowNum: 0
      },
      'content_type_006': {
        max: 9,
        nowNum: 0
      },
      'content_type_007': {
        max: 1,
        nowNum: 0
      },
      group: {
        'content_type_001&content_type_002&content_type_003&content_type_004&content_type_005&content_type_006': {
          max: 9,
          get groupNoWhiteCount() {
            return computedGroupNoWhiteNum(['content_type_002', 'content_type_003', 'content_type_004', 'content_type_005', 'content_type_006'])
          },
          // 匹配值
          get match() {
            return computedMatch('content_type_001&content_type_002&content_type_003&content_type_004&content_type_005&content_type_006')
          },
          get commonKey() {
            return getCommon({}, 'content_type_001&content_type_002&content_type_003&content_type_004&content_type_005&content_type_006')
          }
        }
      }
    },
    // 内容发送
    // 支持最多10个内容设置包括文案及附件（图片、小程序、视频、文件）
    2: {
      'content_type_001': {
        maxNum: 10,
        nowNum: 0
      },
      'content_type_002': {
        maxNum: 10,
        nowNum: 0
      },
      'content_type_003': {
        maxNum: 10,
        nowNum: 0
      },
      'content_type_004': {
        maxNum: 10,
        nowNum: 0
      },
      'content_type_005': {
        maxNum: 10,
        nowNum: 0
      },
      'content_type_006': {
        max: 10,
        nowNum: 0
      },
      group: {
        'content_type_001&content_type_002&content_type_003&content_type_004&content_type_005&content_type_006': {
          max: 10,
          get groupNoWhiteCount() {
            return computedGroupNoWhiteNum(['content_type_001', 'content_type_002', 'content_type_003', 'content_type_004', 'content_type_005', 'content_type_006'])
          },
          // 匹配值
          get match() {
            return computedMatch('content_type_001&content_type_002&content_type_003&content_type_004&content_type_005&content_type_006')
          },
          get commonKey() {
            return getCommon({}, 'content_type_001&content_type_002&content_type_003&content_type_004&content_type_005&content_type_006')
          }
        }
      }
    },
    // 朋友圈
    // 发送内容组合：文案、文案+图片（至多9个）、文案+图文（至多1个）、文案+视频（至多1个）、图片（至多9个）、图文（至多1个）、视频（至多1个）；需屏蔽小程序、文件选择
    3: {
      'content_type_001': {
        maxNum: 1,
        nowNum: 0
      },
      'content_type_002': {
        maxNum: 9,
        nowNum: 0
      },
      'content_type_003': {
        maxNum: 1,
        nowNum: 0
      },
      'content_type_004': {
        maxNum: 0,
        nowNum: 0
      },
      'content_type_005': {
        maxNum: 1,
        nowNum: 0
      },
      'content_type_006': {
        max: 0,
        nowNum: 0
      },
      'content_type_007': {
        maxNum: 1,
        nowNum: 0
      },
      group: {
        'content_type_001&content_type_002': {
          get match() {
            return computedMatch('content_type_001&content_type_002')
          },
          get commonKey() {
            return getCommon({}, 'content_type_001&content_type_002')
          }
        },
        'content_type_001&content_type_003': {
          get match() {
            return computedMatch('content_type_001&content_type_003')
          },
          get commonKey() {
            return getCommon({}, 'content_type_001&content_type_003')
          }
        },
        'content_type_001&content_type_005': {
          get match() {
            return computedMatch('content_type_001&content_type_005')
          },
          get commonKey() {
            return getCommon({}, 'content_type_001&content_type_005')
          }
        },
        'content_type_001&content_type_007': {
          get match() {
            return computedMatch('content_type_001&content_type_007')
          },
          get commonKey() {
            return getCommon({}, 'content_type_001&content_type_007')
          }
        }
      }
    }
  }
  const resoureRule = content[type]
  // 计算附件
  function computedGroupNoWhiteNum(arr, num = 0) {
    arr.map(i => {
      resoureRule[i] && (num = num + resoureRule[i].nowNum)
    })
    return num
  }
  // 计算匹配值
  function computedMatch(keys) {
    const obj = {
      status: false, // 是否大于最大数
      increase: 0 // 匹配值度数
    }
    let num = 0
    const keysArr = keys.split('&')
    keysArr.map(i => {
      if (resoureRule[i] && resoureRule[i].nowNum) {
        resoureRule[i].nowNum > resoureRule[i].maxNum && num++
        obj.increase++
      }
    })
    if (num) {
      obj.status = true
    }
    return obj
  }
  // 获取组里id相同的对象
  function getCommon(obj = {}, keys) {
    const keysArr = keys.split('&')
    keysArr.map(i => {
      for (const group in resoureRule.group) {
        if (group !== keys && group.indexOf(i) !== -1) {
          obj[i] ? obj[i].push(group) : obj[i] = [group]
        }
      }
    })
    return obj
  }
  return resoureRule
}

export const contentMessage = {
  1: '仅支持最多1个文案及最多9个附件类型（图片、图文、小程序、视频、文件）',
  2: '仅支持最多10个内容设置包括文案及附件（图片、小程序、视频、文件）',
  3: '仅支持文案、文案+图片（至多9个）、文案+图文（至多1个）、文案+视频（至多1个）、文案+视频号（至多1个）、图片（至多9个）、图文（至多1个）、视频（至多1个）'
}
