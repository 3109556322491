<template>
  <div class="clueCalendar formBox dFlex dFlexJcsb">
    <h3 :class="['formLabel', { formRequired: formJson.required }]">{{ formJson.screenKey }}</h3>
    <div class="defaultValueBox" @click="show = true">
      <p :class="['valueBox startTime', {placeholder: !showValue[0]}]">{{ showValue[0] ? showValue[0] : '开始日期' }}</p>
      <p class="hyphen">~</p>
      <p :class="['valueBox endTime', {placeholder: !showValue[1]}]">{{ showValue[1] ? showValue[1] : '结束日期' }}</p>
      <div v-if="showClose" class="closeBox" @click.stop="clearValue">
        <i class="iconfont iconclose-o" />
      </div>
      <div v-else class="closeBox" >
        <i class="iconfont iconqianjin" />
      </div>
      <div v-if="formJson.disabled" class="disabledShadow" @click.stop></div>
    </div>
    <van-calendar
      v-model="show"
      :max-date="maxDate"
      :min-date="minDate"
      :default-date="defaultDate"
      allow-same-day
      type="range"
      get-container="#clueForm"
      @confirm="onConfirm" />
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'ClueCalendar',
  model: {
    prop: 'defaultValue',
    event: 'change'
  },
  props: {
    formJson: {
      type: Object,
      required: true,
      default: () => ({})
    },
    defaultValue: {
      type: Array,
      default: () => ([])
    },
    showFormat: {
      type: String,
      default: 'YYYY年MM月DD日'
    },
    confirmFormat: {
      type: String,
      default: 'timeStamp'
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,
      isFocus: false,
      showValue: [],
      defaultDate: []
    }
  },
  computed: {
    showClose() {
      return this.clearable && this.showValue[0]
    },
    maxDate() {
      return this.formJson.maxDate ? new Date(this.formJson.maxDate) : new Date(2030, 11, 31)
    },
    minDate() {
      return this.formJson.minDate ? new Date(this.formJson.minDate) : new Date('2000/01/01')
    }
  },
  watch: {
    defaultValue: {
      deep: true,
      handler(nVal) {
        this.showValue = this.formatTime(nVal, this.showFormat)
      }
    }
  },
  created() {
    this.defaultDate = this.defaultValue.map(item => new Date(item))
    this.showValue = this.formatTime(this.defaultDate, this.showFormat)
  },
  methods: {
    onConfirm(date) {
      this.show = false
      this.showValue = this.formatTime(date, this.showFormat)
      this.defaultDate = date
      this.$emit('change', this.formatTime(date, this.confirmFormat))
    },
    formatTime(date, type) {
      if (!date[0]) return []
      if (type === 'timeStamp') {
        return [
          new Date(`${dayjs(date[0]).format('YYYY/MM/DD')} 00:00:00`).getTime(),
          new Date(`${dayjs(date[1]).format('YYYY/MM/DD')} 23:59:59`).getTime()
        ]
      }
      else {
        return date.map(item => dayjs(item).format(type))
      }
    },
    clearValue() {
      console.log('clearValue')
      this.showValue = []
      this.defaultDate = []
      this.$emit('change', [])
    }
  }
}
</script>

<style lang="less" scoped>
.clueCalendar {
  .defaultValueBox {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    height: 30px;
    border-radius: 2px;
    flex: 1;
    p {
      margin: 0;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: #262626;
    }
    .valueBox {
      // flex: 1;
      &.placeholder {
        color: #999;
      }
    }
    .hyphen {
      width: 14px;
    }
    .closeBox {
      width: 20px;
      text-align: center;
      font-size: 16px;
      line-height: 30px;
      .iconqianjin {
        color: #262626;
      }
      .iconclose-o {
        color: #bfbfbf;
      }
    }
    .disabledShadow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255,255,255,.5);
      z-index: 99;
    }
  }
}
</style>
