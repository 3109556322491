<template>
  <div class="dataCard">
    <cardInfo :item="item" :info-data="infoData" label-width="80px" :top-right-icons="['call']" :is-slot-top="true">
      <div slot="top" class="f16">{{item.name || '群名称待更新...'}}</div>
      <div slot="status" class="clueStatus" :class="[item.groupStatus === 1?'green':'orange']">{{item.groupStatusName}}</div>
    </cardInfo>
    <div class="cardBottom">
      <div  v-if="item.enterGroupFlag" class="cardBut pointer" @click='openQwChat(item.chatId)'>进入群聊</div>
      <div  class="cardBut pointer" @click.stop='toDetail(true)'>编辑</div>
      <div class="cardBut pointer" @click.stop='toDetail(false)'>查看</div>
    </div>
    <van-popup v-model="show" position="right" close-on-click-overlay get-container="body" :style="{ height: '100%',width:'400px' }">
      <component :is="componentName" v-if="show" :id="item.id" v-model="show" v-on="$listeners" />
    </van-popup>
  </div>
</template>
<script>
import cardInfo from '@/components/clue/cardInfo/cardInfo.vue'
import Detail from '@/views/cusGroupChat/detail.vue'
import UpdateDetail from '@/views/cusGroupChat/updateDetail.vue'
import { isMobileClient } from '@/utils/utils'
import mixin from './../mixin'
export default {
  components: { cardInfo, Detail, UpdateDetail },
  mixins: [mixin],
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const that = this
    return {
      isMobile: isMobileClient(),
      infoData: {
        0: {
          label: '群主：',
          value: '张牧野',
          type: 'string',
          // eslint-disable-next-line no-dupe-keys
          get value() {
            return that.item.ownerName || ''
          }
        },
        1: {
          label: '群标签：',
          value: ['到店一次'],
          type: 'tag',
          class: 'orangeTags',
          // eslint-disable-next-line no-dupe-keys
          get value() {
            return that.item.groupTagNames || ''
          }
        },
        2: {
          label: '群客户标签：',
          type: 'tag',
          class: 'greenTags',
          get value() {
            const { groupCusTags } = that.item
            const arr = []
            if (groupCusTags && groupCusTags.length) {
              groupCusTags.map(item => {
                const { tagName, percentage } = item
                arr.push(`${tagName} ${percentage}%`)
              })
            }
            return arr
          }
        },
        3: {
          label: '创建时间：',
          value: '2022-1-24 9:30',
          type: 'string',
          // eslint-disable-next-line no-dupe-keys
          get value() {
            return that.item.groupCreateTime || ''
          }
        },
        4: {
          label: '员工数量：',
          value: '12',
          type: 'string',
          // eslint-disable-next-line no-dupe-keys
          get value() {
            return that.item.empNum || 0
          }
        },
        5: {
          label: '客户数量：',
          value: '12',
          type: 'string',
          // eslint-disable-next-line no-dupe-keys
          get value() {
            return that.item.customerNum || 0
          }
        }
      },
      show: false,
      componentName: ''
    }
  },
  created() {
  },
  methods: {
    toDetail(isEdit) {
      // console.log(isEdit,  '查看')
      // if (this.isMobile) {
      const tenantId = global.APP_CONFIG.tenantId
      const path = isEdit ? `/cusGroupChat/${tenantId}/updateDetail` : `/cusGroupChat/${tenantId}/detail`
      this.$router.push({
        path,
        query: {
          id: this.item.id
        }
      })
      // }
      // else {
      //   this.componentName = isEdit ? 'UpdateDetail' : 'Detail'
      //   this.show = true
      // }
    }
  }
}
</script>
<style lang="less" scoped>
.pointer{
  cursor: pointer;
}
.dataCard{
  padding: 16px 12px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.04);
  .cardBottom {
    height: 50px;
    display: flex;
    justify-content: space-around;
    .cardBut {
      text-align: center;
      line-height: 50px;
      color: #0088FF;
      position: relative;
      width: 50%;
      font-size: 16px;
      &:not(:last-child){
        &::before{
          content: "";
          position: absolute;
          width: 1px;
          height: 50%;
          top: 50%;
          transform:translateY(-50%);
          right: 0;
          background: #F6F7F9;
        }
      }
    }
  }
}
</style>
