<!--
 * @Author: juan.liu
 * @LastEditors: juan.liu
 * @Date: 2022-11-14 11:34:26
 * @LastEditTime: 2022-11-16 15:19:53
 * @FilePath: /scrm-h5/src/views/cusIntention/components/popupModel.vue
-->
<template>
  <div>
    <van-popup v-model="showPicker" class="popup-style" closeable position="bottom">
      <div class="container-box">
        <div class="popup-header">
          <span class="cancel" @click="cancel">取消</span>
          <span class="title">{{title}}</span>
          <span class="submit" @click="confirm">确认</span>
        </div>
        <div class="popup-container">
          <slot/>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>

export default {
  components: { },
  props: {
    title: {
      type: String,
      default: () => { return '' }
    }
  },

  data() {
    return {
      showPicker: false
    }
  },

  computed: {},

  mounted() {
    document.title = ''
  },

  methods: {
    popupToggle() {
      this.showPicker = !this.showPicker
    },
    confirm() {
      this.$emit('confirm')
      this.showPicker = false
    },
    cancel() {
      this.$emit('cancel')
      this.showPicker = false
    }
  }
}

</script>
<style lang='less' scoped>
.popup-style{
  .container-box{
    .popup-header{
      line-height: 50px;
      width: 100%;
      background-color: #fff;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 14px;
      font-size: 14px;
      position: absolute;
      top: 0;
      z-index: 3;
      .cancel{
        color: #5f667a;
        cursor: pointer;
      }
      .title{
        color: #262c37;
        font-weight: 700;
      }
      .submit{
        color: #3173cd;
        cursor: pointer;
      }
    }
    .popup-container{
      padding: 50px 16px 20px 16px;
      max-height: 390px;
      overflow-y: auto;
    }
  }
}
</style>
