<template>
  <div style='padding-bottom: 40px' @close="$emit('close')">
    <div v-for='(item,index) in list' :key='index' class='tags-box'>
      <div class='title'>组合{{ index + 1 }}</div>
      <div class='tags'>
        <div v-for='(t,tIndex) in item' :key='tIndex' class='tag active'>
          <span>{{ t }}</span>
        </div>
      </div>
    </div>
    <div :style="{position: 'absolute',left: 0,bottom: 0,right: 0,zIndex: 9, borderTop: '1px solid #e9e9e9', padding: '10px 20px',background: '#fff',textAlign: 'right',}">
      <el-button type='primary' @click='save'>确 定</el-button>
    </div>
  </div>
</template>

<script>
import { tagsPreview } from '@/api/modules/cusGroupOperateSop.js'

export default {
  name: 'TagsPreview',
  props: {
    tagList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const data = await tagsPreview({ tagList: this.tagList })

      if (data.code === 0) {
        this.list = [].concat(data.data.nameAssembleList)
      }
    },

    save() {
      this.$emit('save')
    }
  }
}
</script>

<style lang='less' scoped>
.tags-box {
  margin: 10px 0;

  .title {
    opacity: 1;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #333333;
  }

  .tags {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .tag {
      width: auto;
      font-size: 12px;
      font-weight: 400;
      padding: 5px 10px;
      margin: 5px 5px;
      display: flex;
      align-items: center;
      height: 15px;
      cursor: pointer;
    }

    .active {
      background: #f3f8fe;
      color: #287de1;
    }

    .noActive {
      background: #f5f5f5;
      color: #666666;
    }
  }
}
</style>
