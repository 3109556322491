<template>
  <div>
    <div class='tools'>
      客户群选择
    </div>
    <div class='content'>
      <div class='tagList'>
        <div class='tag-left'>
          <div v-for='(t,index) in groupList' :key='t.tagId' class='tag' @click='removeTag(index)'>
            <span>{{ t.name }}</span>
            <i class='el-icon-close icon'></i>
          </div>
          <div class='addTag' @click='addTags()'>
            <i class='el-icon-plus add'></i>
            <span>添加客户群</span>
          </div>
        </div>
      </div>
    </div>
    <div class='tools'>
      群标签筛选
    </div>
    <div class='content'>
      <div class='tagList'>
        <div class='tag-left2'>
          <div v-for='(item,index) in tagsList' :key='index' class="tage-box">
            <div v-if='index!== 0 && item.list.length' class='nav'>且</div>
            <div style="display:flex">
              <div v-for='(t,tIndex) in item.list' :key='t.tagId' class='tag' @click='deleteTag(item,tIndex)'>
                <span>{{ t.tagName }}</span>
                <i class='el-icon-close icon'></i>
              </div>
            </div>
          </div>
          <div class='addTag' @click='addTagsChoose("","group")'>
            <i class='el-icon-plus add'></i>
            <span>群标签筛选</span>
          </div>
        </div>
      </div>
    </div>
    <div class='editformBtn' :style="{ position: 'absolute',left: 0,bottom: 0,right: 0,zIndex: 9,borderTop: '1px solid #e9e9e9',padding: '10px 20px',background: '#fff',textAlign: 'right',}">
      <el-button @click='closeDialog'>取 消</el-button>
      <el-button type='primary' @click='saveDialog'>保 存</el-button>
    </div>
    <BaseDialog :visible.sync="showAddGroup" title="客户群选择" width="500px" destroy-on-close :container-style="{ height: 'calc(100% - 60px)', overflow: 'auto', paddingBottom: '30px' }">
      <search-list v-if="showAddGroup" :default-value="defGroup" :fetch-api="getGroupPage" :item-node="itemNode" :is-slot='true' :multiple="true" :search-key="'searchName'" :placeholder="'输入群名称或群主进行搜索'" @close='showAddGroup=false' @save='saveHandle'>
        <template v-slot:model="model">
          <div class="card">
            <img src="@/assets/img/cusGroup/group.png" alt="" class="avar">
            <div class="cont">
              <div v-if="itemNode" class="groupName" v-html="itemNode(model.item)"></div>
              <div v-else class="groupName">{{model.item.name}}</div>
              <div> 群主：{{model.item.ownerName}}</div>
            </div>
          </div>
        </template>
      </search-list>
    </BaseDialog>
    <BaseDialog :visible.sync="showTagChoose" title="群标签筛选" width="500px" destroy-on-close :container-style="{ height: 'calc(100% - 60px)', overflow: 'auto', paddingBottom: '30px' }">
      <tags-choose :tags-list="tagsList" @close="showTagChoose=false" @save="tagsChooseSave"></tags-choose>
    </BaseDialog>
  </div>
</template>
<script>
import SearchList from '@/components/SearchList/index.vue'
import { cus_Grop_new } from '@/api/modules/cusGroupOperateSop.js'
import TagsChoose from '@/views/cusGroupOperateSop/components/tagsChoose.vue'

export default {
  name: 'TagSet',
  components: { SearchList, TagsChoose },
  props: {
    chatGroupList: {
      type: Array,
      default: () => []
    },
    tagList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      groupList: [],
      showAddGroup: false,
      showTagChoose: false,
      defGroup: [],
      getGroupPage: cus_Grop_new,
      tagsList: []
    }
  },
  created() {
    this.groupList = this.chatGroupList
    this.tagsList = this.tagList
    const arr = []
    this.chatGroupList.map(item => {
      arr.push(item.id)
    })
    this.defGroup = arr
  },
  methods: {
    itemNode(item) {
      const { name, groupTagNames } = item
      return `${name}${groupTagNames ? ('【' + groupTagNames + '】') : ''}`
    },
    addTags() {
      this.showAddGroup = true
    },
    saveHandle(list, arr) {
      arr.forEach(item => {
        if (!this.groupList.some(t => t.id === item.id)) this.groupList.push(item)
      })
      this.showAddGroup = false
      this.defGroup = list
    },
    closeDialog() {
      this.$emit('close')
    },
    saveDialog() {
      this.$emit('save', this.tagsList, this.groupList)
    },
    removeTag(index) {
      this.groupList.splice(index, 1)
      const arr = []
      this.groupList.map(item => {
        arr.push(item.id)
      })
      this.defGroup = arr
    },
    addTagsChoose() {
      this.showTagChoose = true
    },
    deleteTag(tagItem, index) {
      tagItem.list.splice(index, 1)
      this.tagsList.forEach((row, i) => {
        if (row.list.length === 0) {
          this.tagsList.splice(i, 1)
        }
      })
    },
    removeTagChoose(index) {
      this.groupList.splice(index, 1)
      const arr = []
      this.groupList.map(item => {
        arr.push(item.id)
      })
      this.defGroup = arr
    },
    tagsChooseSave(list) {
      this.tagsList = list
      this.showTagChoose = false
    }
  }
}
</script>

<style lang='less' scoped>
.editformBtn {
  text-align: right;
}

.tools {
  width: 100%;
  padding: 8px 0;
  color: #f56c6c;
}

.content {
  padding-bottom: 20px;

  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
    }

    .btn {
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #287de1;
      padding: 4px 10px;
    }
  }

  .tagList {
    display: flex;
    flex-direction: row;
    border: 1px solid #f6f6f6;

    .tag-left {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

    }
    .tag-left2{
      width: 100%;
      flex-wrap:wrap
      .tage-box{
        display: flex;
      }
    }
  }
}
.tag {
  width: auto;
  background: #f3f8fe;
  font-size: 12px;
  font-weight: 400;
  color: #287de1;
  padding: 5px 10px;
  margin: 5px 10px;
  display: flex;
  align-items: center;
  height: 15px;
  cursor: pointer;

  .icon {
    margin-left: 5px;
  }
}
.addTag {
  width: 79px;
  color: #287de1;
  background: #fff;
  border: 1px solid #287de1;
  //background: #f5f5f5;
  //border: 1px solid #e8e8e8;
  font-size: 12px;
  //color: #999999;
  padding: 5px 10px;
  margin: 5px 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 15px;
  cursor: pointer;
  .add {
    margin-right: 5px;
  }
}

.card {
  width: 100%;
  background-color: #fff;
  padding: 5px;
  display: grid;
  align-content: center;
  grid-template-columns: 40px auto;
  .avar {
    width: 36px;
    height: 36px;
    border-radius: 5px;
  }
  .cont {
    display: grid;
    align-content: space-between;
    margin-left: 8px;
    font-size: 12px;
    .groupName {
      font-size: 14px;
    }
  }
}
</style>
