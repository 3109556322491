<!--
 * @Author: juan.liu
 * @LastEditors: juan.liu
 * @Date: 2022-11-14 10:05:51
 * @LastEditTime: 2022-11-23 19:10:39
 * @FilePath: /scrm-h5/src/views/cusIntention/components/index.vue
-->
<template>
  <div class="cusIntention">
    <div class="card-header">
      <div class="header-bg"></div>
      <div class="info-box">
        <van-image
          round
          width="81px"
          height="81px"
          fit="cover"
          :src="customInfo.avatar"
        />
        <div class="user-info">
          <div class="nickname">
            <span>{{ customInfo.name }}</span>
            <img
              v-if="customInfo.gender === 2"
              :src="IconFemale"
              class="sex"
            />
            <img v-if="customInfo.gender === 1" :src="IconMale" class="sex" />
          </div>
          <div v-show="customInfo.phoneNumber" class="phone">
            <img
              :src="IconPhone"
              class="icon-phone"
            />
            {{ customInfo.phoneNumber }}
          </div>
        </div>
      </div>
    </div>
    <van-form class="cus-body" @submit="onSubmit">
      <item-field
        v-model="formData.scrmCustomerLastName"
        name="客户姓"
        label="客户姓"
        maxlength="100"
        :readonly = "!isEdit"
        :placeholder="isEdit?'请输入':'-'"
        :rules="rules.input"
      />
      <item-field
        v-model="formData.scrmCustomerName"
        name="客户名"
        label="客户名"
        maxlength="100"
        :placeholder="isEdit?'请输入':'-'"
        :rules="[{ required: true, message: '请输入' },
         ...rules.input]"
      />
      <van-field
        readonly
        clickable
        name="客户性别"
        label="客户性别"
        :placeholder="isEdit?'请选择':'-'"
        :value="formData.scrmCustomerGenderName"
        @click="showPopup('scrmCustomerGender')"
      />
      <van-field
        readonly
        clickable
        name="年龄段"
        label="年龄段"
        :placeholder="isEdit?'请选择':'-'"
        :value="formData.scrmAgeRangeName"
        @click="showPopup('scrmAgeRange')"
      />
      <item-field
        v-model="formData.scrmCustomerWorkIndustry"
        name="行业"
        label="行业"
        rows="1"
        maxlength="100"
        :readonly = "!isEdit"
        :placeholder="isEdit?'请输入':'-'"
        :rules="rules.input"
      />
      <item-field
        v-model="formData.scrmCity"
        name="城市"
        label="城市"
        rows="1"
        maxlength="100"
        :readonly = "!isEdit"
        :placeholder="isEdit?'请输入':'-'"
        :rules="rules.input"
      />
        <van-field
        readonly
        clickable
        name="已有车辆数"
        label="已有车辆数"
        :placeholder="isEdit?'请选择':'-'"
        :value="formData.scrmNumberOfCarsOwnerName"
        @click="showPopup('scrmNumberOfCarsOwner')"
      />
        <van-field
        readonly
        clickable
        name="购买意向"
        label="购买意向"
        :placeholder="isEdit?'请选择':'-'"
        :value="formData.scrmPurchasePlanName"
        @click="showPopup('scrmPurchasePlan')"
      />
        <van-field
        readonly
        clickable
        name="预算"
        label="预算"
        :placeholder="isEdit?'请选择':'-'"
        :value="formData.scrmBudgetName"
        @click="showPopup('scrmBudget')"
      />
      <van-field
        readonly
        clickable
        name="引擎类型"
        label="引擎类型"
        :placeholder="isEdit?'请选择':'-'"
        :value="formData.scrmCarEnergyTypeName"
        @click="showPopup('scrmCarEnergyType')"
      />
      <van-field
        readonly
        clickable
        name="车型"
        label="车型"
        :placeholder="isEdit?'请选择':'-'"
        :value="formData.scrmCarTypeName"
        @click="showPopup('scrmCarType')"
      />

      <item-field
        v-model="formData.scrmPrefereCarBrand"
        name="意向品牌"
        label="意向品牌"
         maxlength="100"
        :readonly = "!isEdit"
        :placeholder="isEdit?'请输入':'-'"
        :rules="rules.input"
      />
      <item-field
        v-model="formData.cdpPhoneNumber"
        name="手机"
        label="手机"
        type="number"
        maxlength="11"
        :readonly = "!isEdit||(customInfo.cdpPhoneNumber!==null&&customInfo.cdpPhoneNumber!=='')"
        :placeholder="!isEdit||(customInfo.cdpPhoneNumber!==null&&customInfo.cdpPhoneNumber!=='')?'-':'请输入'"
        :rules="[{ required: true, message: '请输入'}]"
      />
      <van-field
        readonly
        name="CDPid"
        label="CDPid"
        placeholder="-"
        :value="formData.cdpCustomerId"
      />
      <item-field
        v-model="formData.surveyName"
        name="问卷名称"
        label="问卷名称"
        maxlength="100"
        :readonly = "!isEdit"
        :placeholder="isEdit?'请输入':'-'"
        :rules="[{ required: true, message: '请输入' },
         ...rules.input]"
      />
      <!-- <van-field
        readonly
        clickable
        name="scrmAgeRange"
        label="问卷名称"
        :placeholder="isEdit?'请选择':'-'"
        :value="formData.surveyNameName"
        @click="showPopup('surveyName')"
      /> -->
      <div v-if="isEdit" style="margin: 16px;">
        <van-button round block type="info" color="#4873c0" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <popupToggle ref="popupToggle" :title="popData.title" @confirm="confirm">
      <van-radio-group v-show="popData.list.length>0" v-model="popData.value">
        <van-cell v-for="(item,key) in popData.list" :key="popData.title+key">
          <van-radio  :name="item.value">{{item.name}}</van-radio>
        </van-cell>
      </van-radio-group>
      <div v-show="!popData.list.length>0" class="empty"> 暂无数据 </div>
    </popupToggle>
  </div>
</template>

<script>
import popupToggle from '@/views/cusIntention/components/popupModel.vue'
import itemField from '@/views/cusIntention/components/itemField.vue'
import IconMale from '../../../assets/img/customer/icon_male.png'
import IconFemale from '../../../assets/img/customer/icon_female.png'
import store from '@/store/index'
import { getCustomDetail, updateCustomDetail, getSurveyNameList } from '@/api/modules/cusIntention'

export default {
  components: { popupToggle, itemField },

  data() {
    return {
      IconMale,
      IconFemale,
      IconPhone: require('@/assets/img/customer/icon_phone.png'),
      isEdit: true,
      formData: {
        scrmCustomerLastName: '',
        scrmCustomerName: '',
        // avatar1: require('@/assets/img/avatar.png'),
        // avatar: '',
        scrmCustomerWorkIndustry: '',
        scrmCity: '',
        scrmPrefereCarBrand: '',
        phoneNumber: '',
        cdpPhoneNumber: '',
        scrmCustomerGender: '',
        scrmAgeRange: '',
        scrmNumberOfCarsOwner: '',
        scrmPurchasePlan: '',
        scrmBudget: '',
        scrmCarEnergyType: '',
        scrmCarType: '',
        scrmCustomerGenderName: '',
        scrmAgeRangeName: '',
        scrmNumberOfCarsOwnerName: '',
        scrmPurchasePlanName: '',
        scrmBudgetName: '',
        scrmCarEnergyTypeName: '',
        scrmCarTypeName: '',
        cdpCustomerId: '',
        surveyName: '',
        surveyNameName: '',
        externalId: ''
      },
      customInfo: {
        name: '',
        avatar: '',
        phoneNumber: '',
        gender: '',
        cdpPhoneNumber: ''
      },
      rules: {
        input: [{ pattern: /^[^\s]*$/, message: '不支持空格' }]
      },
      popData: {
        list: [],
        key: '',
        value: '',
        title: ''
      },
      scrmCustomerGenderList: [
        { name: '男', value: 'scrmCustomerGender1' },
        { name: '女', value: 'scrmCustomerGender2' }
      ],
      scrmAgeRangeList: [
        { name: '60后', value: 'scrmAgeRange1' },
        { name: '70后', value: 'scrmAgeRange2' },
        { name: '80后', value: 'scrmAgeRange3' },
        { name: '90后', value: 'scrmAgeRange4' },
        { name: '其他', value: 'scrmAgeRange5' }
      ],
      scrmNumberOfCarsOwnerList: [
        { name: '一辆', value: 'scrmNumberOfCarsOwner1' },
        { name: '两辆', value: 'scrmNumberOfCarsOwner2' },
        { name: '两辆以上', value: 'scrmNumberOfCarsOwner3' },
        { name: '没有', value: 'scrmNumberOfCarsOwner4' }
      ],
      scrmPurchasePlanList: [
        { name: '6个月内', value: 'scrmPurchasePlan1' },
        { name: '6个月到一年', value: 'scrmPurchasePlan2' },
        { name: '一年到两年内', value: 'scrmPurchasePla3' },
        { name: '两年到五年内', value: 'scrmPurchasePla4' },
        { name: '暂时没有计划', value: 'scrmPurchasePla5' }
      ],
      scrmBudgetList: [
        { name: '20万以下', value: 'scrmBudget1' },
        { name: '20万以上-50万', value: 'scrmBudget2' },
        { name: '50万以上-100万', value: 'scrmBudget3' },
        { name: '100万以上-200万', value: 'scrmBudget4' },
        { name: '200万以上-500万', value: 'scrmBudget5' },
        { name: '500万以上', value: 'scrmBudget6' }
      ],
      scrmCarEnergyTypeList: [
        { name: '混合类型', value: 'scrmCarEnergyType1' },
        { name: '纯电动', value: 'scrmCarEnergyType2' },
        { name: '燃油', value: 'scrmCarEnergyType3' }
      ],
      scrmCarTypeList: [
        { name: '双门轿车/双门跑车', value: 'scrmCarType1' },
        { name: '四门轿车/三厢', value: 'scrmCarType2' },
        { name: '五门轿车(掀背式)/两厢', value: 'scrmCarType3' },
        { name: '厢式旅行车（房车）', value: 'scrmCarType4' },
        { name: 'SUV（运动型多功能车/越野车）', value: 'scrmCarType5' },
        { name: 'MPV（多功能用途车）', value: 'scrmCarType6' },
        { name: 'Pick Up（皮卡车）', value: 'scrmCarType7' },
        { name: '微型客车', value: 'scrmCarType8' }
      ],
      surveyNameList: [
        // { name: '问卷名称', value: 'surveyNam1' }
      ],
      titleObj: {
        'scrmCustomerGender': '客户性别',
        'scrmAgeRange': '年龄段',
        'scrmNumberOfCarsOwner': '已有车辆数',
        'scrmPurchasePlan': '购买意向',
        'scrmBudget': '预算',
        'scrmCarEnergyType': '引擎类型',
        'scrmCarType': '车型'
        // 'surveyName': '问卷名称'
      }
    }
  },

  computed: {},

  mounted() {
    document.title = '客户意向'
  },
  created() {
    this.getSurveyNameList()
  },

  methods: {
    async onSubmit(values) {
      console.log('submit', values)
      console.log(this.formData)
      const params = this.formData
      const res = await updateCustomDetail(params)
      if (res.code === 0) {
        this.$toast('操作成功')
        this.getCustomInfo()
      }
      else {
        this.$toast(res.msg)
      }
    },
    onConfirm(value) {
      this.value = value
    },
    showPopup(key) {
      if (!this.isEdit) {
        return
      }
      this.popData.key = key
      this.popData.title = this.titleObj[key]
      this.popData.value = this.formData[key]
      this.popData.list = this[key + 'List']
      this.$refs.popupToggle.popupToggle()
    },
    confirm() {
      const { key, value, list } = this.popData
      const obj = list.find(item => item.value === value)
      this.formData[key] = value
      this.formData[key + 'Name'] = obj.name
      console.log(this.formData)
    },
    async getCustomInfo() {
      const self = this
      // const externalId = store.state.userAuth.customerInfo || 'wmw9oWXAAANKXemZthTSsq6e8r9XSWqg'
      const externalId = store.state.userAuth.customerInfo
      const params = {
        externalId
      }
      // console.log('store.state.userAuth.customerInfo', store.state.userAuth)
      const res = await getCustomDetail(params)
      if (res.code === 0) {
        const data = Object.assign(res.data)
        this.customInfo = {
          avatar: data.avatar,
          gender: data.gender,
          phoneNumber: data.phoneNumber,
          name: data.name,
          cdpPhoneNumber: data.cdpPhoneNumber
          // name: data.scrmCustomerLastName ? data.scrmCustomerLastName : '' + data.scrmCustomerName ? data.scrmCustomerName : ''
        }

        const textData = {}
        for (const [key, value] of Object.entries(data)) {
          console.log(key + ':' + value)
          if (value && self.titleObj[key]) {
            const obj = self[key + 'List'].find(item => item.value === value)
            textData[key + 'Name'] = obj.name
          }
        }
        console.log(textData)
        this.formData = Object.assign(this.$options.data().formData, data, textData, { externalId })
      }
    },
    async getSurveyNameList() {
      const res = await getSurveyNameList()
      if (res.code === 0) {
        if (res.data.length) {
          this.surveyNameList = res.data.map(item => {
            return {
              value: item.surveyKey,
              name: item.surveyName
            }
          })
        }
        this.getCustomInfo()
      }
    }
  }
}

</script>
<style lang='less' scoped>
.cusIntention{
  height: 100vh;
  overflow: scroll;
}
.empty{
  text-align: center;
  font-size: 14px;
  height: 60px;
  line-height: 40px;
  color:#999
}
.card-header {
    position: relative;
    height: 170px;
    box-sizing: border-box;
    background-color: #fff;
    .header-bg {
      width: 100%;
      height: 108px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #4873c0;
    }
    .info-box {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-left: 25px;
      position: relative;
      z-index: 1;
      background: url('../../../assets/img/customer/card_bg.png') no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;

      .user-info {
        box-sizing: border-box;
        padding: 12px 8px;
        height: 81px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .nickname {
          font-size: 18px;
          color: #333;
          font-weight: bold;
          span{
            display: inline-block;
            max-width: 200px;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .sex {
            width: 12px;
          }
        }
        .phone {
          font-size: 14px;
          color: #333;
          display: flex;
          align-items: center;
          .icon-phone {
            width: 14px;
          }
        }
      }
    }
  }
.cus-body{
  background-color: #fff;
  margin: 10px 0;
  padding-bottom: 10px;
}

/deep/ .van-field__control {
    width: calc(100% - 35px)
}

/deep/ .van-field__word-limit {
    position: absolute;
    right: 0;
    top: 0
}
</style>
