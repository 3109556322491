import ajax from '../../utils/ajax'

export default {
  // /v1/access/user/openid
  getOpenId(data) {
    return ajax({
      url: '/v1/access/user/openid',
      method: 'post',
      data
    })
  },
  // http://service-2z0vzqjl-1301971413.sh.apigw.tencentcs.com/get_bd_geo?lng=120.21896&lat=30.20872
  getBaiduPos(params) {
    return ajax({
      url: 'https://service-2z0vzqjl-1301971413.sh.apigw.tencentcs.com/get_bd_geo',
      method: 'get',
      params
    })
  },
  getGaodePos(params) {
    return ajax({
      url: 'https://service-2z0vzqjl-1301971413.sh.apigw.tencentcs.com/get_gd_geo',
      method: 'get',
      params
    })
  },
  // /v1/access/community/collect/like/collect/infos
  getZanInfo(data) {
    return ajax({
      url: '/v1/access/community/collect/like/collect/infos',
      method: 'post',
      data
    })
  },
  setZan(data) {
    // /v1/access/community/collect/like
    return ajax({
      url: '/v1/access/community/collect/like',
      method: 'post',
      data
    })
  },
  getZan(data) {
    // /v1/access/community/collect/info
    return ajax({
      url: '/v1/access/community/collect/info',
      method: 'post',
      data
    })
  },
  get2CNotice(params) {
    return ajax({
      url: '/v1/access/content/notice/get2C',
      method: 'get',
      params
    })
  },
  get2C(params) {
    return ajax({
      url: '/v1/access/content/get2C',
      method: 'get',
      params
    })
  },
  getDealerInfo(params) {
    return ajax({
      url: '/v1/access/employee/getDealerInfo',
      method: 'get',
      params
    })
  },
  // 新增获取经销商卡片信息接口
  getDealerInfoDynamic(data) {
    return ajax({
      url: '/v1/access/employee/getDealerInfoDynamic',
      method: 'post',
      data
    })
  },
  // 企微活动详情
  getAcvitityDetail(data) {
    return ajax({
      url: '/v1/access/activity/detail',
      method: 'post',
      data
    })
  },
  // 小程序活动详情  无顾问信息
  iovgetAcvitityDetail(data, config) {
    return ajax({
      url: '/api/v1/activity/get',
      method: 'post',
      data,
      headers: {
        ...config
      }
    })
  },
  // 活动报名用户列表
  acvitityUserList(data) {
    return ajax({
      url: '/api/v1/activity/sign-user',
      method: 'post',
      data
    })
  },
  // 点赞
  userLike(data, config) {
    return ajax({
      url: '/api/v1/like/like',
      method: 'post',
      data,
      headers: {
        ...config
      }
    })
  },
  // 取消点赞
  userUnLike(data, config) {
    return ajax({
      url: '/api/v1/like/un-like',
      method: 'post',
      data,
      headers: {
        ...config
      }
    })
  },

  shareApi(params) {
    return ajax({
      url: '/access/activity-config/v1/activity/client/share/api/share/config',
      method: 'post',
      data: params
    })
  },
  // http://115.159.15.131/v1/access/content/model/pageList
  modelPageList(data, empId) {
    return ajax({
      url: '/v1/access/content/model/username/pageList',
      method: 'post',
      headers: {
        empId
      },
      data
    })
  },
  // /v1/access/content/redisGet
  redisGet(params, userId) {
    return ajax({
      url: '/v1/access/content/redisGet',
      method: 'get',
      params,
      headers: {
        userId: userId
      }
    })
  },
  // 图文详情
  getNewsDetail(params, config) {
    return ajax({
      url: '/api/v1/content/get',
      method: 'get',
      params,
      headers: {
        ...config
      }
    })
  },
  // 图文库详情
  getImageTextLibDetail(params, config) {
    return ajax({
      url: '/api/v1/imageTextLib/detail',
      method: 'get',
      params,
      headers: {
        ...config
      }
    })
  },
  // 资讯详情页评论列表
  getCommentList(data, config) {
    return ajax({
      url: '/api/v1/comment/query',
      method: 'post',
      data,
      headers: {
        ...config
      }
    })
  },
  // 发表评论
  getCommentPublish(data, config) {
    return ajax({
      url: '/api/v1/comment/publish',
      method: 'post',
      data,
      headers: {
        ...config
      }
    })
  },
  // 评论回复列表
  queryCommentReplay(data, config) {
    return ajax({
      url: '/api/v1/comment/query-replay',
      method: 'post',
      data,
      headers: {
        ...config
      }
    })
  },
  // iam 获取token
  iam_login(data, config) {
    return ajax({
      url: '/api/v1/user/open-login',
      method: 'post',
      data,
      headers: {
        ...config
      }
    })
  },
  iam_login_test(data, config) {
    return ajax({
      url: '/api/v1/activity/wxlogin',
      method: 'post',
      data,
      headers: {
        ...config
      }
    })
  },
  iam_checkLogin(data) {
    return ajax({
      url: '/api/v1/user/verify',
      method: 'post',
      data
    })
  },
  // 获取用户openid
  user_openid(data) {
    return ajax({
      url: '/api/v1/user/openid',
      method: 'post',
      data
    })
  },
  // 埋点用户浏览量
  addScore(data) {
    return ajax({
      url: '/v1/access/content/addScore',
      method: 'post',
      data
    })
  }
}
