<template>
  <div>
    <div v-if="!isMultyPage" class="detail">
      <div v-for="(item, index) in pageContent" :key="index">
        <div v-if="item.type === 'img'" class="imgItems">
          <img style="width: 100%; object-fit: cover" class="d-block" :src="item.imgUrl" alt="" />
        </div>
        <div v-if="item.type === 'form'" class="form">
          <img style="width: 100%; height: 100vh; object-fit: cover" class="d-block" :src="pageFormCentent.formBg" alt="" />
          <div class="formBody">
            <van-cell-group :style="{ '--color': color }">
              <van-field v-model="leaveFormData.name" class="placeholder color" :style="inputStyle" :required="required.name" placeholder="姓名" @blur="handleBlur" />
              <van-field
                v-if="hasMobile"
                v-model="leaveFormData.phone"
                class="placeholder color"
                :style="inputStyle"
                type="number"
                maxlength="11"
                :required="required.mobile"
                placeholder="手机号"
                @blur="handleBlur"
              />
              <van-field
                v-if="hasPhoneCode"
                v-model="leaveFormData.phone"
                class="placeholder color"
                :style="inputStyle"
                type="number"
                maxlength="11"
                :required="required.phoneCode"
                placeholder="手机号"
                @blur="handleBlur"
              />
              <van-field
                v-if="hasPhoneCode"
                v-model="leaveFormData.submitPhoneReq.code"
                class="placeholder color"
                :style="inputStyle"
                center
                type="number"
                :required="required.phoneCode"
                placeholder="验证码"
                @blur="handleBlur"
              >
                <van-button slot="button" size="small" :class="[countdown ? 'reg_disActive' : 'reg_active']" @click="getSmsCode()">
                  <span v-if="countdown">{{ text }}</span>
                  <span v-if="!countdown">{{ text }} ({{ count }})</span>
                </van-button>
              </van-field>

              <van-field
                readonly
                :required="required.seriesCode"
                clickable
                :value="seriseSelected"
                class="placeholder color"
                :style="inputStyle"
                placeholder="意向车系"
                :is-link="isLink"
                @click="OpenSerisePicker()"
              />
              <van-field
                readonly
                :required="required.provinceCity"
                clickable
                :value="provinceCitySelected"
                class="placeholder color"
                :style="inputStyle"
                placeholder="省市"
                :is-link="isLink"
                @click="OpenProvinceCityPicker()"
              />
              <!-- <van-field
                readonly
                :required="required.dealer"
                clickable
                :value="dealerSelected"
                class="placeholder color"
                :style="inputStyle"
                placeholder="经销商"
                :is-link="isLink"
                @click="OpenDelearPicker()"
              /> -->
              <van-field
                v-if="hasApplyTime"
                id="serise"
                readonly
                :required="required.applaytime"
                class="placeholder color"
                :style="inputStyle"
                clickable
                :value="applayTime"
                placeholder="预约时间"
                :is-link="isLink"
                @click="OpenTimePicker()"
              />

              <button class="btn" :style="styleStr" type="default" @click="submit()">{{ pageFormCentent.buttonText }}</button>
            </van-cell-group>
          </div>
          <!-- 车系选择 -->
          <van-popup v-model="showSerisePicker" position="bottom">
            <van-picker show-toolbar :columns="seriesCode" title="选择意向车系" confirm-button-text="确认" @cancel="showSerisePicker = false" @confirm="onConfirmSerise"> </van-picker>
          </van-popup>
          <!-- 经销商选择 -->
          <van-popup v-model="showDelearPicker" position="bottom">
            <van-picker show-toolbar :columns="dealerCode" title="选择经销商" confirm-button-text="确认" @cancel="showDelearPicker = false" @confirm="onConfirmDelear"> </van-picker>
          </van-popup>
          <!-- 省市选择 -->
          <van-popup v-model="showProvinceCityPicker" position="bottom">
            <van-picker :columns="areaList" show-toolbar title="省市选择" @cancel="showProvinceCityPicker = false" @confirm="onConfirmProvinceCity" />
          </van-popup>
          <!-- 自定义时间 -->
          <van-popup v-model="TPshowTimePicker" position="bottom">
            <van-picker :columns="columns" show-toolbar title="预约时间" @change="change" @cancel="TPshowTimePicker = false" @confirm="customerConfrimTime" />
          </van-popup>
        </div>
      </div>
    </div>
    <!-- 多页分页 -->
    <div v-if="isMultyPage" class="detail">
      <van-swipe style="height: 100vh; background: #ccc" vertical :show-indicators="showIndicators" :loop="isloop">
        <van-swipe-item v-for="(item, index) in pageContent" :key="index">
          <div v-if="item.type === 'img'" class="imgItems">
            <img style="width: 100%; height: 100vh; object-fit: cover" class="d-block" :src="item.imgUrl" alt="" />
          </div>
          <div v-if="item.type === 'form'" class="form">
            <img style="width: 100%; height: 100vh" class="d-block" :src="pageFormCentent.formBg" alt="" />
            <div class="formBody">
              <van-cell-group :style="{ '--color': color }">
                <van-field v-model="leaveFormData.name" :required="required.name" :style="inputStyle" class="placeholder color" placeholder="姓名" />
                <van-field v-if="hasMobile" v-model="leaveFormData.phone" :style="inputStyle" type="number" maxlength="11" class="placeholder color" :required="required.mobile" placeholder="手机号" />
                <van-field
                  v-if="hasPhoneCode"
                  v-model="leaveFormData.phone"
                  :style="inputStyle"
                  type="number"
                  maxlength="11"
                  class="placeholder color"
                  :required="required.phoneCode"
                  placeholder="手机号"
                />
                <van-field
                  v-if="hasPhoneCode"
                  v-model="leaveFormData.submitPhoneReq.code"
                  :style="inputStyle"
                  center
                  type="number"
                  class="placeholder color"
                  :required="required.phoneCode"
                  placeholder="验证码"
                >
                  <van-button slot="button" size="small" :class="[countdown ? 'reg_disActive' : 'reg_active']" @click="getSmsCode()">
                    <span v-if="countdown">{{ text }}</span>
                    <span v-if="!countdown">{{ text }} ({{ count }})</span>
                  </van-button>
                </van-field>

                <van-field
                  readonly
                  :required="required.seriesCode"
                  clickable
                  :value="seriseSelected"
                  :style="inputStyle"
                  class="placeholder color"
                  placeholder="意向车系"
                  :is-link="isLink"
                  @click="showSerisePicker = true"
                />
                <van-field
                  readonly
                  :required="required.provinceCity"
                  clickable
                  :value="provinceCitySelected"
                  class="placeholder color"
                  :style="inputStyle"
                  placeholder="省市"
                  :is-link="isLink"
                  @click="OpenProvinceCityPicker()"
                />
                <van-field
                  readonly
                  :required="required.dealer"
                  clickable
                  :value="dealerSelected"
                  class="placeholder color"
                  :style="inputStyle"
                  placeholder="经销商"
                  :is-link="isLink"
                  @click="OpenDelearPicker()"
                />
                <van-field
                  v-if="hasApplyTime"
                  readonly
                  :required="required.applaytime"
                  :style="inputStyle"
                  clickable
                  :value="applayTime"
                  class="placeholder color"
                  placeholder="预约时间"
                  :is-link="isLink"
                  @click="TPshowTimePicker = true"
                />

                <button class="btn" :style="styleStr" type="default" @click="submit()">{{ pageFormCentent.buttonText }}</button>
              </van-cell-group>
            </div>
            <!-- 车系选择 -->
            <van-popup v-model="showSerisePicker" position="bottom">
              <van-picker show-toolbar :columns="seriesCode" title="选择意向车系" confirm-button-text="确认" @cancel="showSerisePicker = false" @confirm="onConfirmSerise"> </van-picker>
            </van-popup>
            <!-- 经销商选择 -->
            <van-popup v-model="showDelearPicker" position="bottom">
              <van-picker show-toolbar :columns="dealerCode" title="选择经销商" confirm-button-text="确认" @cancel="showDelearPicker = false" @confirm="onConfirmDelear"> </van-picker>
            </van-popup>
            <!-- 经销商选择 -->
            <!-- <van-popup v-model="showDelearPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="dealerCode"
                title="选择经销商"
                confirm-button-text="确认"
                @cancel="showDelearPicker = false"
                @confirm="onConfirmDelear"
              >
              </van-picker>
            </van-popup> -->
            <!-- 省市选择 -->
            <van-popup v-model="showProvinceCityPicker" position="bottom">
              <van-picker :columns="areaList" show-toolbar title="省市选择" @cancel="showProvinceCityPicker = false" @confirm="onConfirmProvinceCity" />
            </van-popup>
            <!-- 自定义时间 -->
            <van-popup v-model="TPshowTimePicker" position="bottom">
              <van-picker :columns="columns" show-toolbar title="预约时间" @change="change" @cancel="TPshowTimePicker = false" @confirm="customerConfrimTime" />
            </van-popup>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import modelApi from '../../api/modules/actpage'
import api from '../../api/modules/activity'
// import { setObjToQuery } from '@/utils/mta'
import { Toast } from 'vant'
export default {
  name: 'Actpage',
  components: {},
  data() {
    return {
      TPshowTimePicker: false,
      areaList: [
        {
          text: '请选择',
          children: []
        },
        {
          text: '浙江',
          children: [{ text: '杭州' }, { text: '温州' }]
        }
      ],
      columns: [
        // 第一列
        {
          className: 'year',
          values: [],
          defaultIndex: 0
        },
        // 第二列
        {
          className: 'month',
          values: [],
          defaultIndex: 1
        },
        // 第三列
        {
          className: 'day',
          values: [],
          defaultIndex: 2
        },
        // 第四列
        {
          className: 'hour',
          values: [],
          defaultIndex: 1
        }
      ],
      openid: '',
      inputStyle: '',
      styleStr: '',
      hasDraw: false,
      reSendSMS: false,
      drawData: [],
      hasPhoneCode: false,
      hasApplyTime: false,
      hasMobile: false,

      isLink: true,
      showTimePicker: false,
      showSerisePicker: false,
      showDelearPicker: false,
      showProvinceCityPicker: false,
      applayTime: '',
      count: '',
      timer: '',
      countdown: true,
      text: '发送验证码',

      isMultyPage: false,
      showIndicators: false,
      isloop: false,
      acId: null,
      contentId: null,
      leaveFormData: {
        activityId: null,
        qd: '',
        mt: '',
        leadType: '2',
        mtsub: '',
        phone: '',
        name: '',
        preTime: '',
        seriesCode: '',
        seriesName: '',
        seriesid: '',
        dealerId: '0000000000',
        cityName: '',
        provinceName: '',
        submitPhoneReq: {
          code: null,
          nation: '86',
          phone: null
        }
      },
      seriesCode: [],
      seriesCodes: [],
      seriseSelected: '',

      dealerCode: [],
      dealerCodes: [],
      dealerSelected: '',

      provinceCitySelected: '',
      actPageData: [],
      pageContent: [],
      pageFormCentent: [],
      required: [],
      originHeight: '',
      scrollTop: '',
      flexheight: '',
      isFocus: '',
      scrollHeight: '',
      isFrist: true,
      normalscrollHeight: '',
      color: '#ccc'
    }
  },
  updated() {},
  mounted() {
    window.addEventListener('scroll', this.getScroll, true)
    // 获取.flex的可视区域的高
    const flexheight = document.documentElement.clientHeight
    console.log('this.flexheight', document.querySelector('.detail').scrollHeight)
    this.flexheight = flexheight

    this.originHeight = document.documentElement.clientHeight || document.body.clientHeight
    window.addEventListener('resize', this.handleResize)
  },
  created() {
    // console.log('this.$route.query', this.$route.query)
    if (this.$route.query.contentId) {
      this.contentId = this.$route.query.contentId && decodeURIComponent(this.$route.query.contentId)
    }
    this.acId = this.$route.query.id && decodeURIComponent(this.$route.query.id)
    this.getActPage()
    this.initDateTime()
  },
  methods: {
    // input聚焦
    iptFocus() {
      // ios
      if (this.isIos()) this.isFocus = true
    },
    // input失去焦点
    iptBlur() {
      // ios
      if (this.isIos()) this.isFocus = false
    },
    isIos() {
      var m = navigator.userAgent
      var isIos = !!m.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      const result = !!isIos
      return result
    },
    // android
    handleResize() {
      var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight
      if (resizeHeight < this.originHeight) {
        // 当软键盘弹起，在此处操作
        if (!this.isIos()) this.isFocus = true
        const scrollHeight = document.querySelector('.detail').scrollHeight
        if (this.isFrist) {
          this.originHeightA = scrollHeight
          this.isFrist = false
          console.log(' 当软键盘首次弹起,记录高度 scrollHeightA', this.originHeightA)
        }
        else {
          console.log(' 当软键盘非首次弹起，复原高度 scrollHeightA', this.originHeightA)
          document.querySelector('.detail').style.height = this.originHeightA + 'px'
        }
      }
      else {
        // 当软键盘收起，在此处操作
        if (!this.isIos()) this.isFocus = false
        console.log(' 当软键盘收起')
        // console.log('this.scrollHeight B', document.querySelector('.detail').scrollHeight)
      }
    },
    // 监听屏幕滚动
    getScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 100 && scrollTop < 200) {
        // console.log('getScroll',scrollTop,'  detail:scrollHeight ',document.querySelector('.detail').scrollHeight)
        this.normalscrollHeight = document.querySelector('.detail').scrollHeight
      }
    },
    // 输入框失去焦点时
    handleBlur() {
      document.querySelector('.detail').style.height = this.normalscrollHeight + 'px'
    },
    // 时间弹窗
    OpenTimePicker() {
      this.TPshowTimePicker = true
      this.inputblur()
    },
    // 经销商弹窗
    OpenDelearPicker() {
      this.showDelearPicker = true
      this.inputblur()
    },
    // 省市弹窗
    OpenProvinceCityPicker() {
      this.showProvinceCityPicker = true
      this.inputblur()
    },
    // 车系弹窗
    OpenSerisePicker() {
      this.showSerisePicker = true
      this.inputblur()
    },
    inputblur() {
      window.scrollTo(0, this.normalscrollHeight + 700)
    },
    initDateTime() {
      const nowDate = new Date()
      // 初始化年
      for (let i = 0; i < 6; i++) {
        this.columns[0].values.push(nowDate.getFullYear() + i + '年')
      }
      // 初始化月
      for (let i = 1; i < 13; i++) {
        this.columns[1].values.push(i + '月')
      }
      this.columns[1].defaultIndex = nowDate.getMonth()
      // 初始化日
      for (let i = 1; i < 31; i++) {
        this.columns[2].values.push(i + '日')
      }
      this.columns[2].defaultIndex = nowDate.getDate() - 1
      // 初始化时
      for (let i = 1; i < 25; i++) {
        this.columns[3].values.push(i + '时')
      }
      this.columns[3].defaultIndex = nowDate.getHours() - 1
    },
    // 日期变化修改天数
    change(e) {
      const dataArr = []
      const timeData = e.getValues()
      timeData.forEach((e) => {
        dataArr.push(e.substring(0, e.length - 1))
      })
      this.applayTime = timeData[0] + timeData[1] + timeData[2] + timeData[3]
      const applayTime = dataArr[0] + '/' + dataArr[1] + '/' + dataArr[2] + ' ' + dataArr[3] + ':00:00'
      this.leaveFormData.preTime = Date.parse(new Date(applayTime))
      this.getDaysInMonth(dataArr[0], dataArr[1])
    },
    // 根据年月日获取天数
    getDaysInMonth(year, month) {
      const temp = new Date(year, month, 0)
      const mm = temp.getDate()
      this.columns[2].values = []
      for (let i = 1; i <= mm; i++) {
        this.columns[2].values.push(i + '日')
      }
    },
    // 确认选择时间
    customerConfrimTime(val) {
      const dataArr = []
      const timeData = val
      val.forEach((e) => {
        dataArr.push(e.substring(0, e.length - 1))
      })
      this.applayTime = timeData[0] + timeData[1] + timeData[2] + timeData[3]
      const applayTime = dataArr[0] + '/' + dataArr[1] + '/' + dataArr[2] + ' ' + dataArr[3] + ':00:00'
      this.leaveFormData.preTime = Date.parse(new Date(applayTime))
      this.TPshowTimePicker = false
      document.querySelector('.detail').style.offsetHeight = '100vh'
    },
    // 确认选择车系
    onConfirmSerise(value) {
      this.seriseSelected = value
      const SelectedCode = this.seriesCodes.filter((e) => e.name === value)
      this.leaveFormData.seriesCode = SelectedCode[0].code
      this.leaveFormData.seriesName = SelectedCode[0].name
      this.leaveFormData.seriesid = SelectedCode[0].id
      this.showSerisePicker = false
      document.querySelector('.detail').style.offsetHeight = '100vh'
    },
    // 确认选择经销商
    onConfirmDelear(value) {
      this.dealerSelected = value
      const SelectedCode = this.dealerCodes.filter((e) => e.label === value)
      this.leaveFormData.dealerId = SelectedCode[0].dealerCode
      this.showDelearPicker = false
      document.querySelector('.detail').style.offsetHeight = '100vh'
    },
    // 确认选择省市选择
    onConfirmProvinceCity(value) {
      console.log(value, '5555555555')
      this.provinceCitySelected = value[0] + ' ' + value[1]

      this.leaveFormData.provinceName = value[0].substring(0, value[0].length - 1)

      this.leaveFormData.cityName = value[1].substring(0, value[1].length - 1)
      this.showProvinceCityPicker = false
      document.querySelector('.detail').style.offsetHeight = '100vh'
    },
    // 获取活动页详情
    getActPage() {
      // const data = { id: this.acId }
      modelApi.getActivityPage({ id: this.acId, activityId: this.contentId }).then((res) => {
        console.log('getActivityPage:detail====', res)
        if (res.data.code === 0) {
          this.actPageData = res.data.data
          // 设置单双页
          this.isMultyPage = res.data.data.auditDesc !== '1'
          this.leaveFormData.activityId = this.contentId ? this.contentId : ''
          //  获取openid
          // if (!localStorage.getItem('openid') || localStorage.getItem('openid') == null || localStorage.getItem('openid') === '') {
          //   // 临时注释 上线需要放开
          //   this.getUseropenid()
          // }

          const pageContent = JSON.parse(res.data.data.pageUrlList[0].pageContent)

          pageContent.forEach((e, idx) => {
            if (e.type === 'form') {
              this.pageFormCentent = e
              this.leaveFormData.mt = e.mediaCode
              this.leaveFormData.mtsub = e.mediaSubCode
              this.leaveFormData.qd = e.clueType

              const propertys = {}
              // 设置自定义颜色
              this.getButtonStyle(e)

              e.propertyVoList.forEach((i) => {
                switch (i.propertyCode) {
                  case 'name':
                    propertys.name = !!i.required
                    break
                  case 'phoneCode':
                    this.hasPhoneCode = true
                    propertys.phoneCode = !!i.required
                    break
                  case 'mobile':
                    this.hasMobile = true
                    propertys.mobile = !!i.required
                    break
                  case 'provinceCity':
                    propertys.provinceCity = !!i.required
                    if (i.attr.length > 0) {
                      const province = JSON.parse(i.attr)
                        .filter((item) => item.areaLevel === '1')
                        .map((e) => ({ text: e.name, areaId: e.code }))
                        // .map((e) => ({ text: e.areaName, areaId: e.areaId }))
                      province.forEach((e) => {
                        const citys = JSON.parse(i.attr)
                          .filter((item) => item.parentId === e.areaId)
                          .map((e) => ({ text: e.name }))
                          // .map((e) => ({ text: e.areaName }))
                        e.children = citys
                      })
                      this.areaList = province
                    }
                    break
                  case 'dealer':
                    propertys.dealer = !!i.required
                    if (i.attr.length > 0) {
                      this.dealerCode = JSON.parse(i.attr).map((e) => e.label)
                      this.dealerCodes = JSON.parse(i.attr)
                    }
                    break
                  case 'applaytime':
                    this.hasApplyTime = true
                    propertys.applaytime = !!i.required
                    break
                  case 'seriesCode':
                    propertys.seriesCode = !!i.required
                    if (i.attr.length > 0) {
                      this.seriesCode = JSON.parse(i.attr).map((e) => e.name)
                      this.seriesCodes = JSON.parse(i.attr)
                    }
                    break
                  default:
                    break
                }
              })
              this.required = propertys
            }
            // // 活动页不展示抽奖页面
            // if (e.type === 'draw') {
            //   this.hasDraw = true
            //   this.drawData = e
            //   pageContent.splice(idx, 1)
            // }
            console.log('pageContent', pageContent)
            this.pageContent = pageContent
          })

          // 分享
          // const sondata = {
          //   campaignId: 0,
          //   shareType: 0,
          //   shareUrl: window.location.href
          // }
          // 上报MTA查看活动
          // const contentArg = {
          //   activity_id: this.actPageData.activityId,
          //   activity_name: this.actPageData.activityName,
          //   activity_source: this.actPageData.activityId,
          //   activity_group: this.actPageData.activityId,
          //   activity_sharerid: this.actPageData.activityId ? this.actPageData.activityId : null,
          //   user_id: this.actPageData.activityId
          // }
          // this.$mta.up({
          //   ac: 'ViewActivityCompentPage',
          //   dac: this.actPageData.activityId,
          //   path: window.location.href,
          //   args: setObjToQuery(contentArg),
          //   content: contentArg
          // })
          // api.shareApi(sondata).then((sonres) => {
          //   if (sonres.data.code === 0) {
          //     wx.config({
          //       debug: false,
          //       appId: sonres.data.data.appId,
          //       timestamp: sonres.data.data.timestamp,
          //       nonceStr: sonres.data.data.nonceStr,
          //       signature: sonres.data.data.signature,
          //       jsApiList: ['wx-open-launch-weapp', 'onMenuShareWechat', 'onMenuShareAppMessage', 'onMenuShareTimeline'], // 必填，需要使用的JS接口列表
          //       openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
          //     })
          //     var shareParam = {
          //       imgUrl: this.actPageData.shareIconUrl, // 分享图，默认当相对路径处理，所以使用绝对路径的的话，“http://”协议前缀必须在。
          //       desc: this.actPageData.shareDesc,
          //       title: this.actPageData.shareTitle, // 分享卡片标题
          //       link: window.location.href + '&share=1',
          //       success: () => {}
          //     }
          //     const that_ = this
          //     wx.ready(function() {
          //       wx.onMenuShareAppMessage(shareParam) // 分享给好友
          //       wx.onMenuShareTimeline(shareParam) // 分享朋友圈
          //       wx.onMenuShareWechat(shareParam) // 企业微信分享
          //       that_.showtplBtn = true
          //       that_.$nextTick(() => {
          //         try {
          //           var btn = document.getElementById('launch-btn')
          //           btn.addEventListener('launch', function(e) {})
          //           btn.addEventListener('error', function(e) {})
          //         }
          //         catch (error) {
          //           console.log(error)
          //         }
          //       })
          //     })
          //     wx.error(function() {})
          //   }
          // })
        }
        else {
          Toast(res.data.msg)
        }
      })
    },
    // 提交留资
    async submit() {
      if (this.required.name) {
        if (this.leaveFormData.name.length === 0) {
          Toast('请输入姓名')
          return
        }
      }
      if (this.required.mobile) {
        if (this.leaveFormData.phone.length === 0) {
          Toast('请输入手机号')
          return
        }
      }
      if (this.required.phoneCode) {
        if (this.leaveFormData.phone.length === 0) {
          Toast('请输入手机号')
          return
        }
      }
      if (this.required.phoneCode) {
        if (this.leaveFormData.submitPhoneReq.code.length === 0) {
          Toast('请输入验证码')
          return
        }
      }
      if (this.required.seriesCode) {
        if (this.leaveFormData.seriesCode.length === 0) {
          Toast('车系不能为空')
          return
        }
      }
      if (this.required.provinceCity) {
        if (this.leaveFormData.provinceName.length === 0 || this.leaveFormData.cityName.length === 0) {
          Toast('省市不能为空')
          return
        }
      }
      if (this.required.dealer) {
        if (this.leaveFormData.dealerId === '0000000000') {
          Toast('经销商不能为空')
          return
        }
      }
      if (this.required.applaytime) {
        if (this.leaveFormData.preTime.length === 0) {
          Toast('预约时间不能为空')
          return
        }
      }
      if (this.required.phoneCode) {
        const data = {
          nation: '86',
          phone: this.leaveFormData.phone,
          code: this.leaveFormData.submitPhoneReq.code
        }
        const res = await modelApi.checkSmsCode(data)
        if (res && res.data.code !== 0) {
          Toast('请输入正确的验证码')
          return
        }
      }
      console.log('this.leaveFormData', this.leaveFormData)
      this.leaveFormData.submitPhoneReq.phone = this.leaveFormData.phone
      // const allowCookie = localStorage.getItem('cookie')
      modelApi.leaveInfo(this.leaveFormData).then((res) => {
        console.log('leaveInfo:', res)
        if (res.data.code === 0) {
          // if (this.hasDraw) {
          //   const userinfo = {
          //     actid: this.leaveFormData.activityId,
          //     userName: this.leaveFormData.name,
          //     userPhone: this.leaveFormData.phone,
          //     openid: localStorage.getItem('openid')
          //   }
          //   this.$router.push({
          //     path: '/draw',
          //     query: { data: this.drawData, userinfo: userinfo }
          //   })
          // }
          // else {
          Toast('提交成功')
          // }
        }
        else {
          Toast(res.data.msg)
        }
      })
      // const userinfo = {
      //   actid:237,
      //   userName:'zhangkai',
      //   userPhone:'15157158823',
      //   openid:'ofoy_v7PUx2yERemjySqKccejlOs'
      // }
      // this.$router.push({
      //   path: '/draw',
      //   query: { data: this.drawData, userinfo: userinfo }
      // })
    },
    getCodeTime() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.countdown = false
        this.text = '重新发送'
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          }
          else {
            this.countdown = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    // 获取验证码
    getSmsCode() {
      this.inputblur()
      if (!this.countdown) {
        return
      }
      if (this.leaveFormData.phone.length === 0) {
        Toast('请输入手机号')
        return
      }
      else if (!this.validPhone(this.leaveFormData.phone)) {
        Toast('请输入正确手机号')
        return
      }
      const data = {
        nation: '86',
        phone: this.leaveFormData.phone
      }
      modelApi.getSmsCode(data).then((res) => {
        if (res.data.code === 0) {
          this.getCodeTime()
          // localStorage.setItem('cookie', res.data.data.cookie)
          // this.reSendSMS = true
        }
      })
    },
    validPhone(val) {
      const reg = /^1(\d){10}$/
      return reg.test(val)
    },
    // 自定义样式
    getButtonStyle(formData) {
      this.inputStyle = 'background:' + formData.formBorderColor + ';color:' + formData.formWordsColor
      this.styleStr = 'background-color:' + formData.buttonColor + ';border:' + formData.buttonColor + ';color:' + formData.buttonTextColor
      // 设置placehodercolor
      this.color = formData.formWordsColor
    },
    changCode(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const url = window.location.href.split('#')[0]
      const search = url.split('?')[1]
      if (search) {
        var r = search.substr(0).match(reg)
        if (r !== null) return unescape(r[2])
        return null
      }
      else {
        return null
      }
    },
    getUseropenid() {
      const code = this.changCode('code')
      var pageUrl = window.location.href.replace(/[/]/g, '%2f').replace(/[:]/g, '%3a').replace(/[#]/g, '%23').replace(/[&]/g, '%26').replace(/[=]/g, '%3d')
      if (code == null || code === '') {
        window.location.href =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + process.env.VUE_APP_ID + '&redirect_uri=' + pageUrl + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
      }
      else {
        api.getOpenId({ code }).then((res) => {
          if (res.data.code === 0) {
            if (res.data.data) {
              this.openid = res.data.data
              console.log('openid:', res.data.data)
              localStorage.setItem('openid', res.data.data)
            }
            else {
              this.openid = ''
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.imgItems {
  // height: 100vh;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0px;
}
.customeTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
}
.customeCancel {
  color: #0088ff;
  font-size: 17px;
}
.customeConfirm {
  color: #0088ff;
  font-size: 17px;
}
.van-cell-group {
  background-color: initial;
  width: 90%;
  padding: 0 28px;
}
.van-cell {
  margin-bottom: 30px;
  border-radius: 100px;
  height: 44px;
}
.detail {
  width: 100%;
  // height: 100vh;
  /* padding: 15px 20px 30px 20px; */
  overflow-y: scroll;
}
.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.smscode {
  width: 7.7em !important;
}
.buttonGetSmsCode {
  width: 1rem;
  height: 0.32rem;
}
.submit {
  width: 14em;
  height: 2em;
  margin-bottom: 10px;
}
.formBody {
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.btn {
  width: 100%;
  height: 44px;
  border-radius: 100px;
  font-size: 14px;
}
.seriseSelect {
  width: 2.25rem;
  height: 0.35rem;
  outline: none;
}

.reg_active {
  // color:#1592FF;
  color: var(--color) !important;
  border: none;
}
.reg_disActive {
  color: #323233;
  border: none;
}
// 设置输入框placehodercolor
.color {
  color: var(--color) !important;
  ::placeholder {
    color: var(--color);
  }
}
.placeholder {
  color: #fff;
}
// 设置输入框placehodercolor
</style>
