<template>
  <div class="cardMain">
    <template v-if="isSlotTop">
      <slot name="top" />
    </template>
    <div v-else class="cardTop">
      <div class="headPortraitBox">
        <img :src='item.avatar ? item.avatar : avatarPic' alt=''>
        <i v-if="item.gender" :class="['iconfont', {iconnan: item.gender == 1}, {iconnv: item.gender == 2}]" />
      </div>
      <p class="namePhone onellipsis">{{item.name}} {{ item.phone }}</p>
      <div class="iconBox">
        <i v-if="topRightIcons.includes('call')" class="iconfont icondianhuaicon pointer" @click.stop="call(item.phone)" />
        <i v-if="initWx && item.externalUserid && topRightIcons.includes('openChat')" class="iconfont iconqiyeweixin pointer" @click.stop="openQwChat(item.externalUserid)"/>
        <i v-if="!item.externalUserid && topRightIcons.includes('addCus')" class="iconfont iconjiaweixin pointer copyBut" @click="addCustomerWx(item.phone)" />
      </div>
    </div>
    <div class="cardContent">
      <slot name="status" />
      <template v-for="(it,key) in infoData">
        <info v-if="(it.hasOwnProperty('isHidden') && it.isHidden) || !it.hasOwnProperty('isHidden')" :key="key" class="cardInfo" :item="it" v-bind="$attrs" />
      </template>
    </div>
  </div>
</template>
<script>
import info from '../info/info.vue'
// import mixinMain from '@/pages/clue/mixin'
export default {
  components: { info },
  // mixins: [mixinMain],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: [Array, Object],
      default: () => ({})
    },
    topRightIcons: {
      type: Array,
      default: () => (['call', 'openChat', 'addCus'])
    },
    isSlotTop: {
      type: [Boolean, String, Number],
      default: false
    }
  }
}
</script>
<style lang="less" scoped>
.cardMain{
  .cardTop {
    display: flex;

    .headPortraitBox {
      position: relative;
      margin-right: 8px;
      width: 32px;
      height: 32px;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .iconfont {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 12px;
        border-radius: 50%;
        background: #fff;
        width: 12px;
        height: 12px;
        line-height: 1;
        &.iconnan {
          color: #2c78ea;
        }

        &.iconnv {
          color: #E23F34;
        }
      }
    }

    .namePhone {
      flex: 1;
      margin: 0;
      font-size: 16px;
      line-height: 32px;
      font-weight: 500;
      color: #262626;
    }

    .iconBox {
      margin-left: 12px;
      // width: 32px;
      // height: 32px;
      // line-height: 32px;
      text-align: center;
      color: #0088FF;
      .iconfont {
        font-size: 24px;
      }
    }
  }

  .cardContent {
    position: relative;
    margin-top: 12px;
    padding: 12px;
    background: #F4F8FE;
    border-radius: 4px;

    .contentItem {
      display: flex;
      margin-bottom: 8px;
      color: #666666;

      .contentLabel {
        width: 92px;
        font-size: 13px;
        line-height: 18px;

        &.followUpPerson {
          letter-spacing: 4px;
        }
      }

      .itemContent {
        flex: 1;
        font-size: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .textContent {
          display: inline-block;
          margin-right: 8px;
          font-size: 13px;
          line-height: 18px;
        }

        .remark {
          width: 100%;
        }

        .tagItem {
          margin-right: 8px;
          display: inline-block;
          padding: 0 3px;
          font-size: 10px;
          line-height: 16px;
          color: #0088FF;
          border-radius: 2px;
          border: 1px solid #fff;
        }

        .redTag {
          color: #FF823A;
          border-color: #FF823A;
        }

        .greenTag {
          color: #00CD00;
          border-color: #00CD00;
        }

        .blueTag {
          color: #0088FF;
          border-color: #0088FF;
        }
      }
    }

    .clueStatus {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 12px;
      font-size: 12px;
      line-height: 24px;
      border-radius: 0px 4px 0px 12px;
    }

    .orange {
      color: #FF823A;
      background: #F6E0D7;
    }

    .green {
      color: #00CD00;
      background: #CCF5CC;
    }

    .blue {
      color: #0088FF;
      background: rgba(0, 136, 255, 0.2);
    }

    .red {
      color: #FF5257;
      background: #F6D7DD;
    }
  }
 /deep/ .cardInfo{
  height: 25px;
  overflow: hidden;
  color: #666;
    .label,.value{
      font-size: 13px;
    }
    .value{
      display: block !important;
      overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
      word-break: break-all;
      >div{
        display: block !important;
        overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
        word-break: break-all;
      }
    }
  }
}

</style>
