<!--
 * @Author: ke.xue
 * @Date: 2021-12-17 11:24:06
 * @LastEditors: ke.xue
 * @LastEditTime: 2022-01-18 15:10:19
 * @FilePath: \qw-scrm-h5\components\clue\checkbox.vue
 * @Description: 自定义多选组件
-->
<template>
  <div class="clueCheckbox formBox">
    <h3 :class="['formLabel', { formRequired: formJson.required }]">{{ formJson.screenKey }}</h3>
    <div class="screenValueBox">
      <div
        v-for="(item, index) in screenValue"
        :key="index"
        :class="['screenValueItem', {active: item.active}]"
        @click="onChange(index)"
      >{{ item[labelKey] }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClueCheckbox',
  model: {
    prop: 'defaultValue',
    event: 'change'
  },
  props: {
    formJson: {
      type: Object,
      required: true,
      default: () => ({})
    },
    defaultValue: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      screenValue: [],
      active: [],
      valueKey: 'code',
      labelKey: 'name'
    }
  },
  watch: {
    'formJson.screenValue': {
      deep: true,
      handler(nVal) {
        const { screenValue, valueKey, labelKey } = this.formJson
        this.screenValue = screenValue.map(item => {
          valueKey && (item.code = item[valueKey])
          labelKey && (item.name = item[labelKey])
          return item
        })
      }
    },
    defaultValue: {
      deep: true,
      handler(nVal) {
        this.active = nVal
        const activeObj = {}
        nVal.forEach(item => {
          activeObj[item] = true
        })
        this.screenValue = this.screenValue.map(item => {
          activeObj[item[this.valueKey]] && (item.active = true)
          return item
        })
      }
    }
  },
  created() {
    this.init(this.formJson)
  },
  methods: {
    init(obj) {
      const { screenValue, defaultValue, valueKey, labelKey } = obj
      this.active = defaultValue
      const activeObj = {}
      defaultValue.forEach(item => {
        activeObj[item] = true
      })
      this.screenValue = screenValue.map(item => {
        valueKey && (item.code = item[valueKey])
        labelKey && (item.name = item[labelKey])
        activeObj[item[this.valueKey]] && (item.active = true)
        return item
      })
    },
    onChange(index) {
      // console.log(this.screenValue, index, this.active)
      const screenValue = JSON.parse(JSON.stringify(this.screenValue))
      if (screenValue[index].active) {
        screenValue[index].active = false
        this.active = screenValue.filter(item => { return item.active }).map(item => item[this.valueKey])
      }
      else {
        screenValue[index].active = true
        this.active.push(screenValue[index][this.valueKey])
      }
      this.screenValue = screenValue
      this.$emit('change', this.active)
    }
  }
}
</script>

<style lang="less" scoped>
.clueCheckbox {
  .screenValueBox {
    .screenValueItem {
      border-radius: 2px;
    }
  }
}
</style>
