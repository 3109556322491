<!--
 * @Author: ke.xue
 * @Date: 2021-12-17 11:23:48
 * @LastEditors: ke.xue
 * @LastEditTime: 2022-01-05 14:54:37
 * @FilePath: \qw-scrm-h5\components\clue\radio.vue
 * @Description: 自定义单选组件
-->
<template>
  <div class="clueRadio formBox">
    <h3 :class="['formLabel', { formRequired: formJson.required }]">{{ formJson.screenKey }}</h3>
    <div class="screenValueBox">
      <div
        v-for="(item, index) in screenValue"
        :key="index"
        :class="['screenValueItem', {active: item.active}]"
        @click="onChange(index)"
      >{{ item[labelKey] }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClueRadio',
  model: {
    prop: 'defaultValue',
    event: 'change'
  },
  props: {
    formJson: {
      type: Object,
      required: true,
      default: () => ({})
    },
    defaultValue: {
      type: [String, Number, Object],
      default: ''
    }
  },
  data() {
    return {
      screenValue: [],
      active: '',
      valueKey: 'code',
      labelKey: 'name'
    }
  },
  watch: {
    'formJson.screenValue': {
      deep: true,
      handler(nVal) {
        const { screenValue, valueKey, labelKey } = this.formJson
        this.screenValue = screenValue.map(item => {
          valueKey && (item.code = item[valueKey])
          labelKey && (item.name = item[labelKey])
          return item
        })
      }
    },
    defaultValue(nVal) {
      this.screenValue = this.screenValue.map(item => {
        if (String(item.code) === String(nVal)) {
          item.active = true
        }
        else {
          item.active = false
        }
        return item
      })
    }
  },
  created() {
    this.init(this.formJson)
  },
  methods: {
    init(obj) {
      const { screenValue, defaultValue, valueKey, labelKey } = obj
      this.active = defaultValue
      this.screenValue = screenValue.map(item => {
        valueKey && (item.code = item[valueKey])
        labelKey && (item.name = item[labelKey])
        if (String(defaultValue) === String(item[this.valueKey])) {
          item.active = true
        }
        return item
      })
    },
    onChange(index) {
      const screenValue = JSON.parse(JSON.stringify(this.screenValue))
      if (screenValue[index].active) {
        if (this.formJson.required) return
        screenValue[index].active = false
        this.active = ''
        this.screenValue = screenValue
      }
      else {
        this.screenValue = screenValue.map((item, sub) => {
          item.active = index === sub
          return item
        })
        this.active = screenValue[index][this.valueKey]
      }
      // console.log(this.screenValue, index, this.active)
      this.$emit('change', this.active)
    }
  }
}
</script>

<style lang="less" scoped>
.clueRadio {
  .screenValueBox {
    .screenValueItem {
      border-radius: 12px;
    }
  }
}
</style>
