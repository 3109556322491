<!--
 * @Author: chenyc
 * @Date: 2021-09-06 17:09:38
 * @LastEditTime: 2021-09-16 15:28:07
 * @LastEditors: chenyc
 企业群发对应内容
-->
<template>
  <div class="enterpriseMdist">
    <div v-for="(item, index) in itemArray" :key="index">
      <item-midst
:ref="'validate' + index"
v-bind="$attrs"
:index-num="index"
:stage-index='stageIndex'
:conterstr="item"
:max-length="itemArray.length"
:disabled="isDisabled"
:video-feel="videoFeel"
:validator-info="validatorInfo"
:indiv-type='indivType'
:is-group='isGroup'
@addSubmit="addSubmit"
@deleteSubmit="deleteSubmit"
@deleteList="deleteList"
@radioChage="radioChage" />
    </div>
  </div>
</template>

<script>
import mixin from '@/views/cusGroupOperateSop/mixin'
import { createUidKey } from '@/utils/utils'
export default {
  name: 'EnterpriseMdist',
  components: {
    ItemMidst: () => import('./itemMidst')
  },
  mixins: [mixin],
  // 拓展设置 文章 和 附件限制
  props: {
    iTemArray: {
      type: Array,
      default: () => []
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    // 是否显示视频号
    videoFeel: {
      type: Boolean,
      default: false
    },
    limitArticle: {
      type: Number,
      default: 1
    },
    limitEnclosure: {
      type: Number,
      default: 9
    },
    indivType: {
      type: String,
      default: () => ''
    },
    isGroup: {
      type: Boolean,
      default: false
    },
    stageIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 模板数据
      itemArray: [],
      // itemArray: [
      //   {
      //     indexNum: 0,
      //     isAdd: true,
      //     isType: false,
      //     data: {
      //       contentType: 'content_type_001'
      //       // id: null
      //     }
      //   }
      // ],
      // 本地记录删除数据模板id
      deleteContentIdList: []
    }
  },
  computed: {},
  watch: {
    itemArray: {
      handler() {
        this.$emit('onChage', this.itemArray)
      },
      deep: true
    }
  },
  created() { },
  mounted() {
    this.$nextTick(() => {
      if (this.iTemArray.length) {
        const arr = []
        this.iTemArray.forEach((x, index) => {
          const uid = createUidKey()
          arr.push({
            indexNum: index,
            uid,
            // isAdd: true,
            // isType: false,
            data: x
          })
          this.resoureRule[x.contentType] && this.resoureRule[x.contentType].nowNum++
        })
        // this.$set(this.itemArray, index, str);
        // this.itemArray = arr
        this.itemArray = JSON.parse(JSON.stringify(arr))
      }
      else {
        const uid = createUidKey()
        const ctype = 'content_type_001'
        this.itemArray = [
          {
            indexNum: 0,
            // isAdd: true,
            // isType: false,
            uid,
            data: { contentType: ctype }
          }
        ]
        this.resoureRule[ctype] && this.resoureRule[ctype].nowNum++
      }
    })
    // const array = [];
    // this.iTemArray.length
    //   ? this.iTemArray.forEach((ele, index) => {
    //       const obj = {
    //         indexNum: index,
    //         // isAdd: index === this.iTemArray.length - 1 ?? true,
    //         data: ele
    //       };
    //       if (index < this.limitArticle && ele.contentType === 'content_type_001') {
    //         obj.isType = false;
    //       } else {
    //         obj.isType = true;
    //       }
    //       array.push(obj);
    //     })
    //   : false;
    // if (array.length) {
    //   this.itemArray = array;
    // }
  },

  methods: {
    /**
     * @description: 新增一个新模板
     * @Autor: chenyc
     * @Date: 2021-09-08 16:24:31
     * @param {*} row
     * @return {*}
     * 1.新增模板步骤 拿到(limitarticle 文章限制数量) 判断之前数据是否有文章类型
     */
    addSubmit(row) {
      const uid = createUidKey()
      const newStr = {
        indexNum: row.indexNum + 1,
        // isAdd: true,
        // isType: istype,
        uid,
        data: {
          // contentType: type
        }
      }
      this.itemArray.push(newStr)
    },
    // addSubmit(row) {
    //   // 用于判断是否 超出文章数量限制
    //   const isIndex = _.filter(this.itemArray, { data: { contentType: 'content_type_001' } }).length;
    //   // 数组过滤掉
    //   let isIndexArr = _.differenceWith(this.itemArray, [{ data: { contentType: 'content_type_001' } }], _.isEqual).length;
    //   // 用于判断附件 超出数量限制
    //   let type = null;
    //   let istype = false;
    //   if (isIndex < this.limitArticle) {
    //     // 相同时无法创建 文章
    //     istype = false;
    //     type = 'content_type_001';
    //     // return
    //   } else {
    //     // 默认除文章之后 的第一个类型
    //     istype = true;
    //     type = 'content_type_002';
    //   }
    //   if (isIndexArr > this.limitEnclosure) {
    //     this.$message.error(`附件已超出${this.limitEnclosure}限制，无法继续新增`);
    //     return;
    //   }
    //   // 新增之后 其余的取消新增按钮
    //   // const res = this.itemArray.find(x => x.indexNum === row.indexNum);
    //   // res.isAdd = false;
    //   // res.isType = istype;
    //   const newStr = {
    //     indexNum: row.indexNum + 1,
    //     // isAdd: true,
    //     isType: istype,
    //     data: {
    //       contentType: type
    //     }
    //   };
    //   this.itemArray.push(newStr);
    // },
    // 触发删除模板
    async deleteSubmit({ index, oldVal }) {
      const { resoureRule } = this
      oldVal && resoureRule[oldVal].nowNum > 0 && resoureRule[oldVal].nowNum--
      await this.updateValidate()
      this.itemArray.splice(index, 1) // 删除点击的下标
      // if (this.itemArray.length < 1) {
      //   // 当数组小于0 等于0 赋值false
      //   this.itemArray[0].isAdd = false;
      // } else {
      //   this.itemArray[this.itemArray.length - 1].isAdd = true;
      // }
    },
    deleteList(id) {
      this.deleteContentIdList.push(id)
      this.$store.dispatch('auth/setDeletList', this.deleteContentIdList)
    },
    async radioChage({ value, oldVal }) {
      await this.validateRes(value, oldVal)
      await this.updateValidate()
    },
    // radioChage() {
    //   const isIndex = _.filter(this.itemArray, { data: { contentType: 'content_type_001' } }).length;
    //   if (isIndex < this.limitArticle) {
    //     this.itemArray.map(x => (x.isType = false));
    //   } else {
    //     const Indexes = _.keys(_.pickBy(this.itemArray, { data: { contentType: 'content_type_001' } }));
    //     this.itemArray.forEach((res, index) => {
    //       if (Indexes.indexOf(index + '') == -1) {
    //         res.isType = true;
    //       }
    //     });
    //   }
    // },
    // 参数暴露
    SUBMIT_FORM() {
      return this.itemArray
      // const err = this.submitForm();
      // return !err ? this.form : null;
    },
    // 删除参数暴露
    // DELETE_FORM() {
    //   return this.deleteContentIdList.length ? this.deleteContentIdList : []
    // },
    // 校验是否必填
    CHECK_REQUIRED() {
      const { validatorInfo } = this
      return new Promise((resolve) => {
        let num = 0
        if (validatorInfo.status) {
          this.$message.error(validatorInfo.message)
          return
        }
        this.itemArray.forEach((res, index) => {
          this.$nextTick(() => {
            this.$refs['validate' + index][0].VALI_DATE().then((res) => {
              if (res) {
                num++
                if (num === this.itemArray.length) {
                  resolve(res)
                }
              }
            })
          })
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
@deep: ~'>>>';
// 穿透修改样式 布局
.enterpriseMdist {
  @{deep} .el-form {
    .el-row {
      margin-left: 0 !important;
      .el-form-item {
        .el-form-item__label {
          text-align: left !important;
        }
      }
    }
  }
}
</style>
