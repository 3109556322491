<template>
  <div class="formBox">
    <van-field
      :label="formJson.screenKey"
      readonly
      clickable
      name="picker"
      :value="getValue"
      :placeholder="formJson.placeholder || '请选择'"
      class="input"
      input-align="right"
      @click="showAddAlert = true"
    >
      <template #right-icon>
        <van-icon v-if="value.length" name="close" @click.stop="brandSystemDelete" />
        <van-icon v-else name="arrow" />
      </template>
    </van-field>
    <dialog-search-list
      :fetch-api="formJson.fetchApi"
      :show-add-alerts="showAddAlert"
      :multiple="multiple"
      :options="{
        value: formJson.valueKey || 'id',
        name: formJson.labelKey || 'name'
      }"
      :default-val='value'
      @comfirm='confirm'
@cancel='cancel' />
  </div>
</template>
<script>
import DialogSearchList from '@/components/dialogSearchList.vue'
export default {
  components: { DialogSearchList },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    formJson: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [Number, String, Array],
      default: ''
    },
    isShowLabel: {
      type: [Boolean, String, Number],
      default: true
    }
  },
  data() {
    return {
      showAddAlert: false,
      // name : '',
      multiple: true
    }
  },
  computed: {
    getValue() {
      const { value, multiple, formJson } = this
      if (multiple) {
        return value.length ? value.length + (formJson.unit || '') : ''
      }
      else {
        return value
      }
    }
  },
  methods: {
    cancel() {
      this.showAddAlert = false
    },
    confirm(value) {
      this.$emit('change', value)
      this.cancel()
    },
    brandSystemDelete() {
      this.$emit('change', this.multiple ? [] : '')
    }
  }
}
</script>
<style lang="less" scoped>
.input{
  padding:10px 0;
  /deep/ .van-field__label{
    color: #262626;
    font-size: 14px;
  }
}
</style>
