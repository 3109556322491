/*
 * @Author: juan.liu
 * @LastEditors: juan.liu
 * @Date: 2022-11-16 11:02:52
 * @LastEditTime: 2022-11-16 18:23:48
 * @FilePath: /scrm-h5/src/api/modules/cusIntention.js
 */
import { post } from '@/api/index'

// 获取客户详情
export const getCustomDetail = params =>
  post('/dapi/user-profile/v1/customer/card/getCustomerInfo', params)

// 获取客户详情
export const updateCustomDetail = params =>
  post('/dapi/user-profile/v1/customer/card/save', params)

// 获取问卷名称
export const getSurveyNameList = params =>
  post('/dapi/user-profile/v1/customer/card/getSurveyName')

