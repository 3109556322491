<!--
 * @Author: chenyc
 * @Date: 2021-05-10 10:46:18
 * @LastEditTime: 2021-06-21 16:25:54
 * @LastEditors: chenyc
-->
<template>
  <div class="search-box">
    <!-- 搜索框 -->
    <div class="search-box-top">
      <van-search v-model.trim="keyword" :placeholder="placeholder" :class="isTopShow?'activeShow':''" :readonly="isreadonly" :show-action="showAction" @search="toSeach" @clear="toSeach">
        <template #action>
          <div v-if="isIconCancel" style="display:flex;" @click.stop="iconClick()">
            <i :style="iconfontStyle" class="iconfont" :class="iconfont"></i>
          </div>
          <div v-if="!isIconCancel" @click.stop="textClick()">{{isIconText}}</div>
        </template>
      </van-search>
    </div>
    <!-- 其他 -->
    <div class="search-box-other"></div>
  </div>
</template>

<script>
export default {
  name: 'TopSearch',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: '输入搜索内容'
    },
    isTopShow: {
      type: Boolean,
      default: false
    },
    isreadonly: {
      type: Boolean,
      default: false
    },
    showAction: {
      type: Boolean,
      default: true
    },
    iconfont: {
      type: String,
      default: 'iconfilter'
    },
    iconfontStyle: {
      type: Object,
      default: () => {
        return {
          'font-size': '30px',
          'color': '#999999'
        }
      }
    },
    isIconCancel: {
      type: Boolean,
      default: true
    },
    isIconText: {
      type: String,
      default: '取消'
    },
    searchValue: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      keyword: ''
      // topShow: false
    }
  },

  computed: {},
  watch: {
    searchValue(val) {
      this.keyword = val
    }
  },
  mounted() {},
  methods: {
    // 内容搜索
    toSeach() {
      this.$emit('search', this.keyword)
    },
    // icon 按钮事件
    iconClick() {
      // this.topShow = !this.topShow
      this.$emit('iconclick')
    },
    // 文字按钮事件
    textClick() {
      this.$emit('textClick', this.keyword)
    },
    // 对外开发方法
    onClear() {
      // 清除输入框
      this.keyword = ''
    },
    foreign() {

    }
  }
}

</script>
<style lang='less' scoped>
@deep: ~">>>";
.search-box {
  // width: 100%;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 3000;
  @{deep} .search-box-top {
    .van-search {
      padding: 12px 16px;
      &.activeShow {
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.04);
      }
      .van-search__content {
        background: #F5F5F5;
        border-radius: 4px;
        padding-right: 4px;
        .van-cell {
          padding: 7px 2px 7px 0;
          .van-icon {
            color: #BFBFBF;
          }
        }
      }
      .van-search__action{
        color: #999999;
        font-size: 14px;
        padding-left: 12px;
        &:active{
        background: none;
        }
      }
    }
  }
  // 其他
  // .search-box-other {}
}
</style>
