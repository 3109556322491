import { contentConfig, contentMessage } from '@/config/contentConfig'

export default {
  props: {
    // 触达类型 -  如果是朋友圈不显示小程序和文件
    indivType: {
      type: String,
      default: () => ''
    }
  },
  data() {
    let type = 1
    switch (this.indivType) {
      case 'content_reach_003':
        type = 2
        break
      case 'content_reach_004':
        type = 3
        break
    }
    return {
      validatorInfo: {
        message: contentMessage[type],
        status: false
      },
      multipleStatus: false,
      resoureRule: contentConfig(type)
    }
  },
  methods: {
    validateRes(value, oldVal) {
      this.$nextTick(() => {
        const { resoureRule } = this
        console.log('old', oldVal, 'new', value)
        if (oldVal) {
          resoureRule[oldVal] && resoureRule[oldVal].nowNum > 0 && resoureRule[oldVal].nowNum--
        }
        resoureRule[value] && resoureRule[value].nowNum++
        // formBak[field] = value;
      })
    },
    async updateValidate() {
      await this.multiple()
      console.log(this.multipleStatus, 444)
      // this.validateChange(this.multipleStatus)
      this.validatorInfo.status = this.multipleStatus
    },
    multiple() {
      this.$nextTick(() => {
        const { resoureRule } = this
        const { group = {}} = resoureRule
        let status = false
        /*
        1. 超过不符合
        2. 匹配度高的，其它组有值不符合
        2.1 当前组有值
        2.2 种类匹配多
        3. 附件组合最大值
      */
        let matchObj = ''
        for (const key in group) {
          const row = group[key]
          matchObj =
            matchObj && group[matchObj.key].match.increase >= row.match.increase
              ? matchObj
              : { ...row, key, keyArr: key.split('&') }
        }
        (matchObj.match.status || matchObj.max && matchObj.groupNoWhiteCount > matchObj.max) && (status = true)
        if (!status) {
          for (const key in matchObj.commonKey) {
            const commonItem = matchObj.commonKey[key]
            commonItem.map(i => {
              const keyArr = i.split('&')
              keyArr.map(j => {
                if (key !== j && resoureRule[j] && resoureRule[j].nowNum) {
                  status = true
                }
              })
            })
          }
        }
        this.multipleStatus = status
      })
    }
  }
}
