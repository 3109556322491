<template>
  <div>
    <div class='pageTop'>
      <div class='searchFilter'>
        <TopSearch placeholder="输入客户群" :iconfont-style="getIconfontStyle" :search-value="findContent" @search="changeSearch" @iconclick="clickFormShow" />
      </div>
      <div class="typeFilter">
        <TypeFilter :active="[groupStatus]" :list="tabList" :is-show-num="false" @change="changeStatus"/>
      </div>
    </div>
    <div ref="list" class="pageContent">
      <Scroll :height="listHeight+'px'">
        <List ref="dataList" class="scollList" :tab-index="0" :fetch-api="fetchApi" :param-data='paramData'>
          <template v-slot:default="{item, index}">
            <card :key="item.id" :item="item" :index="index"  @pageUpdate="resetting" />
          </template>
        </List>
      </Scroll>
    </div>
    <SearchForm
      v-if="formShow"
      v-model="formShow"
      :height="getFormOption.height"
      :position="getFormOption.position"
      :width="getFormOption.width"
      :overlay="getFormOption.overlay"
      :form-json="formJson"
      :safe-area-inset-bottom="false"
      :close-overlay="true"
      class="clueDefeatApplyForm"
      @reset="screenFormReset"
      @confirm="screenFormConfirm"
    />
  </div>
</template>
<script>
import List from '@/components/baseList/list.vue'
import TypeFilter from '@/components/typeFilter.vue'
import TopSearch from '@/components/topSearch/topSearch'
import Scroll from '@/components/scroll.vue'
import Card from './component/card.vue'
import SearchForm from '@/components/clue/form.vue'
import mixin from './mixin'
import groupChatApi from '@/api/modules/cusGroupChat'
import wxSDK from '@/scripts/wxsdk'
export default {
  name: 'CusGroupChat',
  components: { List, TopSearch, Scroll, Card, TypeFilter, SearchForm },
  mixins: [mixin],
  data() {
    const that = this
    return {
      isFilter: false,
      findContent: '',
      formShow: false,
      listHeight: 0,
      groupTags: [],
      tabList: [
        { headNum: 0, headStr: '', headValue: '全部' },
        { headNum: 0, headStr: 1, headValue: '正常' },
        { headNum: 0, headStr: 2, headValue: '待继承' }
      ],
      formJson: [
        // {
        //   type: 'SelectPage',
        //   screenKey: '群主',
        //   // screenValue: [],
        //   defaultValue: [],
        //   submitKey: 'ownerUserIds',
        //   valueType: 'list',
        //   valueKey: 'userid',
        //   labelKey: 'name',
        //   placeholder: '请选择',
        //   fetchApi: that.getOwnerList,
        //   unit: '人'
        // },
        {
          type: 'ClueCalendar',
          screenKey: '任务时间',
          screenValue: [],
          defaultValue: [],
          submitKey: 'groupCreateTime',
          valueType: 'list'
          // minDate: new Date(),
        },
        {
          type: 'ClueCheckboxNew',
          screenKey: '群标签',
          get screenValue() {
            return that.groupTags || []
          },
          defaultValue: [],
          valueKey: 'id',
          labelKey: 'tagName',
          submitKey: 'groupTagIds',
          valueType: 'list'
        }
      ],
      groupStatus: '',
      paramData: {
        ownerUserIds: [],
        groupTagIds: [],
        groupCreateTime: []
      }
    }
  },
  computed: {
    getIconfontStyle() {
      const isFilter = this.isFilter
      const obj = { 'font-size': '30px' }
      if (isFilter) {
        obj.color = '#0088FF'
      }
      else {
        obj.color = '#999'
      }
      return obj
    },
    getFormOption() {
      // 移动端
      const obj = {
        width: '100%',
        height: 'calc(100% - 100px)',
        position: 'bottom',
        overlay: true
      }
      // try {
      //   if (!this.isInWechatMP) {
      //     obj = {
      //       width: '400px',
      //       height: '100%',
      //       position: 'right',
      //       overlay: true
      //     }
      //   }
      // }
      // catch (error) {
      //   console.log(error)
      // }
      return obj
    }
  },
  async created() {
    this.$nextTick(() => {
      this.listHeight = document.documentElement.clientHeight - this.$refs.list.offsetTop - 50
    })
    await wxSDK.config([
      'openEnterpriseChat'
    ], [
      'openEnterpriseChat'
    ])
    this.getTagList({
      type: 10003,
      subType: 1000301
    }).then(this.thenResolve).then(res => {
      this.groupTags = res
    })

    // this.getOwnerList()
  },
  mounted() {
    document.title = '客户群管理'
  },
  methods: {
    // tab变化
    changeStatus(val) {
      this.groupStatus = val
      this.resetting()
    },
    // 获取群主列表
    async getOwnerList(params = {}) {
      // let arr=[]
      return groupChatApi.groupOwnerList(params)
      // res.dataList.map(item=>{
      //   arr.push({
      //     code:item.userid,
      //     name:item.name
      //   })
      // })
      // this.formJson[0].screenValue=arr
      // console.log(res.dataList,'群主列表')
    },
    clickFormShow() {
      if (!this.formShow) {
        const { groupTagIds, groupCreateTime } = this.paramData
        // this.formJson[0].defaultValue = ownerUserIds
        this.formJson[0].defaultValue = groupCreateTime
        this.formJson[1].defaultValue = groupTagIds
      }
      this.formShow = !this.formShow
    },
    // 按群名筛选
    changeSearch(val) {
      this.findContent = val
      this.resetting()
    },
    // 列表接口
    fetchApi(params = {}) {
      const { ownerUserIds, groupTagIds, groupCreateTime } = this.paramData
      const [start = '', end = ''] = groupCreateTime
      const info = {
        ...params,
        paramData: {
          ownerUserIds,
          groupTagIds,
          groupCreateTimeStartLong: start,
          groupCreateTimeEndLong: end,
          groupStatus: this.groupStatus,
          name: this.findContent,
          orderFields: [{ fieldName: 'group_create_time', order: 'DESC' }]
        }
      }
      return groupChatApi.pageList(info)
    },
    // 筛选表单重置
    screenFormReset() {

    },
    // 筛选表单确认
    screenFormConfirm(params, options = {}) {
      console.log(params, options, '筛选')
      const { isFilter } = options
      this.isFilter = isFilter
      this.paramData = params
      this.clickFormShow()
      this.resetting()
    },
    // 从第一页开始请求接口
    resetting() {
      try {
        this.$refs.dataList.resetting()
        document.getElementById('scrollView').scrollTop = 0
      }
      catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

