/** * @Author: junlan.he * @date: 2021/9/3 * @desc: */
<template>
  <div class="preview">
    <div class="imgParent">
      <!-- <h5 class="fellowName text-one-hidden">{{ previewTitle ? previewTitle : '王西西' }}</h5> -->
      <h5 class="fellowName text-one-hidden">{{ '王西西 ' + previewTitle }}</h5>
      <div class="previewShadow">
        <p class="nowTime">{{ currentTime }}</p>
        <div v-if="previewType === 'welcome'" class="welcome welcomeLeft">
          <div class="imageBox messageBoxLeft textSty">
            <div>
              <p class="lineHeight">我通过了你的联系人验证请求，现在我们可以开始聊天了</p>
            </div>
          </div>
          <img class="head" src="~@/assets/img/cusGroup/customHead.png" alt="" />
        </div>
        <!--  v-if="sopV2" -->
        <div v-if="sopV2">
          <!-- 循环发送内容列表 -->
          <div v-for="(item, index) in contentList" :key="index" class="welcome sopV2">
            <!-- 提示性话语 -->
            <div v-if="reachType!=='content_reach_001'">
              <p class="nowTime" style="marginBottom:5px">阶段{{index+1}}的内容</p>
              <!--
              <div class="contSty">
                <img class="head" src="~@/assets/images/employeHead.png" alt="" />
                <div class="imageBox messageBox textSty">
                  <div>
                    <p>以下是阶段{{index+1}}的内容，是否能帮到您？</p>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- 不是朋友圈 -->
            <!--  v-if="reachType!=='content_reach_004'" -->
            <div>
              <div v-for="(it, ind) in item.sopContentList" :key="ind" class="contSty">
                <!-- 类型为文案 -->
                <img v-if="it.contentObj.appname || it.contentObj.content || it.contentObj.description || it.contentObj.fileName || it.contentObj.imgUrl || it.contentObj.logo || it.contentObj.picUrl || it.contentObj.title || it.contentObj.msgtype === 'video' || it.contentObj.msgtype === 'file'" class="head" src="~@/assets/img/cusGroup/employeHead.png" alt="" />
                <div v-if="it.contentObj.msgtype === 'text' && it.contentObj.content" class="imageBox messageBox textSty">
                  <div>
                    <p class="lineHeight">{{ it.contentObj.content }}</p>
                  </div>
                </div>
                <!-- 类型为图片 -->
                <div v-if="it.contentObj.msgtype === 'image' && it.contentObj.imgUrl" class="imageBox messageBox">
                  <img :src="it.contentObj.imgUrl" alt="" />
                </div>
                <!-- 类型为图文 -->
                <div v-if="it.contentObj.msgtype === 'news' && (it.contentObj.title || it.contentObj.description || it.contentObj.picurl)" class="imageBox messageBox news">
                  <div class="pt_tit ellipsis2 lineHeight">{{ it.contentObj.title }}</div>
                  <div class="new_down">
                    <div class="pt_desc ellipsis2 lineHeight">{{ it.contentObj.description }}</div>
                    <img class="pt_img" :src="it.contentObj.imgUrl" alt="" />
                  </div>
                </div>
                <!-- 类型为小程序 -->
                <div v-if="it.contentObj.msgtype === 'miniprogram_notice' && (it.contentObj.title || it.contentObj.picurl)" class="appletsBox messageBox">
                  <h4 class="appletsDesc text-two-hidden lineHeight">{{ it.contentObj.title }}</h4>
                  <div v-if="it.contentObj" class="appletsTitle">
                    <div v-if="it.contentObj.logo" class="logo">
                      <img :src="it.contentObj.logo" alt="" />
                    </div>
                  </div>
                </div>
                <!-- 类型为视频 -->
                <div v-if="it.contentObj.msgtype === 'video' || it.contentObj.msgtype === 'video'" class="videoBox messageBox">
                  <video :src="it.contentObj.picUrl" alt="avatar" autoplay controls>你的浏览器不支持该属性</video>
                </div>
                <!-- 类型为文件 -->
                <div v-if="it.contentObj.msgtype === 'file'" class="rightMaterials messageBox file">
                  <img src="~@/assets/img/cusGroup/word.jpg" class="file_img" alt="" />
                  <div class="newsContent">
                    <h4 class="newsTitle text-two-hidden file_tit lineHeight">{{ it.contentObj.fileName }}</h4>
                    <p class="newsText text-three-hidden file-sz lineHeight">{{ it.contentObj.size }}</p>
                    <div v-if="it.contentObj.picUrl" class="newsIcon"><img :src="it.contentObj.picUrl" alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 是朋友圈 -->
            <!-- <div v-else>
              <div v-if="item.text.contentObj||item.other.contentObj||item.img.length" class="reach_004_sty">
                <img class="head" src="~@/assets/images/employeHead.png" alt="" />
                <div class="imageBox messageBox">
                  <div class="reach_004_Box">
                    <img class="head" src="~@/assets/images/employeHead.png" alt="" />
                    <div class="reach_004_Box_right">
                      <div class="content">
                        <div class="name">王西西</div>
                        <div v-if="item.text.contentObj ">
                          <div v-if="item.text.contentObj.msgtype === 'text' && item.text.contentObj.content" class=" textSty">
                            <div>
                              <p>{{ item.text.contentObj.content }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="imgListSty">
                          <div v-for="(it, ind) in item.img" :key="ind">
                            <div v-if="it.contentObj">
                              <div v-if="it.contentObj.msgtype === 'image' && it.contentObj.imgUrl" class="">
                                <img :src="it.contentObj.imgUrl" alt="" class="imgSty" :class="ImgWidthFun(item.img.length)==='wid1'?'imgSty_1':(ImgWidthFun(item.img.length)==='wid2'?'imgSty_2':'imgSty_3')" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="item.other.contentObj">
                          <div v-if="item.other.contentObj.msgtype === 'news' && (item.other.contentObj.title || item.other.contentObj.description || item.othercontentObj.picurl)" class=" news">
                            <div class="pt_tit ellipsis2">{{ item.other.contentObj.title }}</div>
                            <div class="new_down">
                              <div class="pt_desc ellipsis2">{{ item.other.contentObj.description }}</div>
                              <img class="pt_img" :src="item.other.contentObj.imgUrl" alt="" />
                            </div>
                          </div>
                          {{item.other.contentObj.picUrl}}
                          <div v-if="item.other.contentObj.msgtype === 'video'" class="videoBox  ">
                            <video :src="item.other.contentObj.picUrl" alt="avatar" class="videoBox004" autoplay controls>你的浏览器不支持该属性</video>
                          </div>
                        </div>
                        <div class="bottom">
                          <span>刚刚</span><span>...</span>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </div>

            </div> -->
          </div>

          <!-- 发送内容列表结束 -->
        </div>

        <div v-else>
          <!-- 循环发送内容列表 -->
          <div v-for="(item, index) in sendContent" :key="index" class="welcome">
            <!-- 类型为文案 -->
            <img v-if="item.appname || item.content || item.description || item.fileName || item.imgUrl || item.logo || item.picUrl || item.title || item.msgtype === 'video' || item.msgtype === 'file'" class="head" src="~@/assets/img/cusGroup/employeHead.png" alt="" />
            <div v-if="item.msgtype === 'text' && item.content" class="imageBox messageBox textSty">
              <div>
                <p class="lineHeight">{{ item.content }}</p>
              </div>
            </div>
            <!-- 类型为图片 -->
            <div v-if="item.msgtype === 'image' && item.imgUrl" class="imageBox messageBox">
              <img :src="item.imgUrl" alt="" />
            </div>
            <!-- 类型为图文 -->
            <div v-if="item.msgtype === 'news' && (item.title || item.description || item.picurl)" class="imageBox messageBox news">
              <div class="pt_tit ellipsis2 lineHeight">{{ item.title }}</div>
              <div class="new_down">
                <div class="pt_desc ellipsis2 lineHeight">{{ item.description }}</div>
                <img class="pt_img" :src="item.imgUrl" alt="" />
              </div>
            </div>
            <!-- 类型为小程序 -->
            <div v-if="item.msgtype === 'miniprogram_notice' && (item.title || item.picurl)" class="appletsBox messageBox">
              <h4 class="appletsDesc text-two-hidden lineHeight">{{ item.title }}</h4>
              <div v-if="item" class="appletsTitle">
                <div v-if="item.logo" class="logo">
                  <img :src="item.logo" alt="" />
                </div>
                <!-- <p class="appletsName text-one-hidden">{{ item.description }}</p> -->
              </div>
              <!-- <h4 class="appletsDesc text-two-hidden">{{ item.description }}</h4> -->
              <!-- <div v-if="item.picUrl" class="appletsContent">
              <img src="~@/assets/images/wxapp.jpg" alt="" />
            </div> -->
              <!-- <div v-for="(i, ind) in item.content_item" :key="ind" class="appCon">
              <span class="appConTit">{{ i.key }}</span>
              <span class="appConCon">{{ i.value }}</span>
            </div> -->
            </div>

            <!-- 类型为视频 -->
            <div v-if="item.msgtype === 'video' || item.msgtype === 'video'" class="videoBox messageBox">
              <video :src="item.picUrl" alt="avatar" autoplay controls>你的浏览器不支持该属性</video>
            </div>
            <!-- 类型为文件 -->
            <div v-if="item.msgtype === 'file'" class="rightMaterials messageBox file">
              <img src="~@/assets/img/cusGroup/word.jpg" class="file_img" alt="" />
              <div class="newsContent">
                <h4 class="newsTitle text-two-hidden file_tit lineHeight">{{ item.fileName }}</h4>
                <p class="newsText text-three-hidden file-sz lineHeight">{{ item.size }}</p>
                <div v-if="item.picUrl" class="newsIcon"><img :src="item.picUrl" alt="" /></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preview',
  props: {
    sendContent: {
      type: Array,
      default: () => []
    },
    previewTitle: {
      type: String,
      default: ''
    },
    // 如果是欢迎语内容，需要传入previewType为welcome
    previewType: {
      type: String,
      default: ''
    },
    reachType: {
      type: String,
      default: ''
    },
    sendContentObj: {
      type: Array,
      default: () => []
    },
    sopV2: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nowTime: new Date(),
      contentList: []
    }
  },
  computed: {
    currentTime() {
      const d = new Date()
      const y = d.getFullYear()
      const m = d.getMonth() + 1
      const t = d.getDate()
      const h = d.getHours()
      let f = d.getMinutes()
      f = f < 10 ? '0' + f : f
      const str = h > 12 ? '下午' : '上午'
      return y + '/' + m + '/' + t + ' ' + str + h + ':' + f
      /* const str = dayjs(this.nowTime).format('A') === 'AM' ? '上午 ' : '下午 ';
      return str + dayjs(this.nowTime).format('h:mm'); */
    },
    fellowName() {
      if (this.title) {
        return this.title
      }
      return this.fellow[0] ? this.fellow[0]?.nickName : ''
    },
    fellowNamePhone() {
      return this.fellowName.slice(0, 1)
    }
    /* welcomeContent() {
      if (!this.editorText) return '';
      return this.editorText.replace(/\#FELLOW\#/g, this.fellowName).replace(/\#CUSTOMER\#/g, '×××');
    }, */

  },
  watch: {
    list: {
      deep: true,
      handler(val) {
        console.log(val, 'Preview')
      }
    },
    sendContentObj(val) {
      // const tmpList = []
      this.contentList = this.sendContentObj

      // 如果是朋友圈，改变数据格式
      // if (this.reachType === 'content_reach_004') {
      //   if (val) {
      //     val.map((item, index) => {
      //       let obj = {
      //         text: {},
      //         img: [],
      //         other: {}
      //       }
      //       item.sopContentList?.map((it, ind) => {
      //         if (it.contentObj.msgtype === 'text') {
      //           obj.text = it
      //         }
      //       })
      //       item.sopContentList?.map((it, ind) => {
      //         console.log(it.contentObj.msgtype, 88888);
      //         if (it.contentObj.msgtype !== 'text' && it.contentObj.msgtype === 'image') {
      //           obj.img.push(it)
      //         } else if (it.contentObj.msgtype !== 'text' && it.contentObj.msgtype !== 'image') {
      //           obj.other = it
      //         }
      //       })
      //       tmpList.push(obj)
      //     })
      //   }

      //   console.log(tmpList, 'TTTTTTTTTTTTTTT');
      //   this.contentList = tmpList
      // } else {
      //   this.contentList = this.sendContentObj
      // }
    }
  },
  methods: {
    ImgWidthFun(leng) {
      let widthTmp = ''
      switch (leng) {
        case 1:
          widthTmp = 'wid1'
          break
        case 2:
          widthTmp = 'wid2'
          break
        default: widthTmp = 'wid3'
          break
      }
      return widthTmp
    }
  }
}
</script>

<style lang="less" scoped>
.imgParent {
  position: relative;
  margin: 0 auto;
  width: 280px;
  height: 580px;
  background: url('../../../assets/img/cusGroup/iphone-device-skin.png') no-repeat;
  background-size: 100% 100%;
  .fellowName {
    position: absolute;
    top: 40px;
    left: 30px;
    margin: 0;
    width: 218px;
    height: 26px;
    font-size: 14px;
    // font-weight: 600;
    line-height: 26px;
    text-align: center;
    color: #000;
    // background: #ebebeb;
  }
  .previewShadow {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 12px;
    padding-bottom: 10px;
    width: 256px;
    height: 448px;
    background: rgba(235, 235, 235, 1);
    overflow-y: auto;
    text-align: left;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background-color: #999;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #333;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
    .nowTime {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }
    .welcome {
      display: flex;
      margin-top: 8px;
      padding: 0 3px;
      // flex-direction: column;
      .reach_004_sty {
        width: 240px;
        display: flex;
      }
      .contSty {
        display: flex;
        margin-bottom: 5px;
      }
      .welcomeName {
        display: inline-block;
        width: 26px;
        height: 26px;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        background: #000;
        color: #fff;
        border-radius: 4px;
      }
      .head {
        width: 30px;
        height: 30px;
      }
      .welcomeContent {
        padding: 3px 6px;
        max-width: 190px;
        font-size: 14px;
        line-height: 20px;
        word-break: break-all;
        white-space: pre-wrap;
        text-align: left;
        border-radius: 4px;
      }
      .imageBox,
      .videoBox {
        border: 1px solid #999;
        border-radius: 4px;
        padding: 8px 5px;
        color: #000;
        img,
        video {
          max-height: 200px;
          max-width: 188px;
        }
      }

      .news {
        padding: 10px;
        border: 1px solid #eee;
        border-radius: 2px;
        .ellipsis2 {
          display: -webkit-box;
          /*! autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          word-break: break-all;
        }
        .new_down {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .pt_img {
            max-height: 30px;
            max-width: 188px;
          }
        }
        .pt_tit,
        .pt_desc {
          line-height: 1.1;
        }
        .pt_tit {
          // font-weight: 600;
          font-size: 16px;
          color: #000;
          margin-bottom: 5px;
        }
        .pt_desc {
          // margin-top: 10px;
          color: #666;
        }
      }
      .file {
        width: 300px;
        padding: 8px 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .file_img {
          width: 50px;
          height: 50px;
        }
        .newsContent {
          display: flex;
          flex-direction: column;
          padding: 10px 5px;
          overflow: hidden;
          .file_tit {
            margin-bottom: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .file-sz {
            font-size: 12px;
          }
        }
      }
      .appletsBox {
        box-sizing: border-box;
        padding: 4px;
        width: 190px;
        border: 1px solid #999;
        border-radius: 4px;
        .appletsTitle {
          display: flex;
          width: 100%;
          height: 20px;
          .logo {
            margin-right: 12px;
            border-radius: 10px;
            font-size: 0;
            img {
              width: 20px;
              width: 20px;
            }
          }
          .appletsName {
            margin-bottom: 0;
            flex: 1;
            line-height: 20px;
            font-size: 12px;
          }
        }
        .appletsDesc {
          font-size: 14px;
          line-height: 20px;
          min-height: 20px;
          max-height: 40px;
        }
        .appletsContent {
          width: 180px;
          height: 150px;
          background: #eee;
          border-radius: 4px;
          img {
            width: 180px;
            height: 150px;
          }
        }
        .appCon {
          .appConTitBig {
            font-size: 20px;
          }
          .conBigger {
            font-size: 20px;
          }
          .appConTit {
            color: #999;
          }
        }
      }
      .messageBox {
        position: relative;
        margin-left: 8px;
        max-width: 190px;
        background: #fff;
      }
      .messageBox::before {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        left: -6px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 6px solid #fff;
        border-bottom: 5px solid transparent;
      }
      .messageBoxLeft {
        position: relative;
        margin-right: 8px;
        max-width: 190px;
        background: #fff;
      }
      .messageBoxLeft::before {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        right: -6px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 6px solid #fff;
        border-bottom: 5px solid transparent;
      }
      .reach_004_Box {
        padding: 8px;
        display: flex;
      }
      .reach_004_Box_right {
        width: 150px;
        .content {
          margin-left: 5px;
          .name {
            margin-bottom: 10px;
          }
          .imgListSty {
            display: flex;
            width: 160px;
            flex-wrap: wrap;

            .imgSty {
              padding: 2px;
            }
            .imgSty_1 {
              width: 145px;
              // height: 45px;
              object-fit: cover;
            }
            .imgSty_2 {
              width: 65px;
              height: 65px;
              object-fit: cover;
            }
            .imgSty_3 {
              width: 45px;
              height: 45px;
              object-fit: cover;
            }
          }
          .videoBox004 {
            width: 135px !important;
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            font-size: 12px;
            color: #666;
          }
        }
      }
    }
    .welcomeLeft {
      justify-content: flex-end;
    }
    .sopV2 {
      flex-direction: column;
    }
  }
  .lineHeight {
    line-height: 1.5;
    font-size: 13px;
    letter-spacing: 0.1em;
  }
}
</style>
