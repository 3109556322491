<template>
  <div class="filter">
    <div class="filter-l f12">
      <div
        v-for='item in list'
        :key='item[code]'
        class="pointer filter-l-item"
        :class="[{active: active.includes(item[code])}]"
        @click='filterChange(item)'
      >{{ item.headValue }}<span v-if="isShowNum">{{ item.headNum }}</span></div>
    </div>
    <div v-if="isShowRight" class="filter-r pointer" @click="selcetAll">批量分配</div>
  </div>
</template>
<script>
export default {
  // model:{
  //   prop:'active',
  //   event: 'change'
  // },
  props: {
    list: {
      type: Array,
      default: () => ([
        { headNum: 0, headStr: 'all', headValue: '全部' }
        // { headNum: 0, headStr: '-1', headValue: '已逾期' },
        // { headNum: 0, headStr: '0', headValue: '待分配' },
        // { headNum: 0, headStr: '1', headValue: '已分配' }
      ])
    },
    active: {
      type: [String, Number, Array],
      default: () => ([])
    },
    selcetAll: {
      type: Function,
      default() {}
    },
    isShowRight: {
      type: [String, Number, Boolean],
      default: false
    },
    code: {
      type: String,
      default: 'headStr'
    },
    isShowNum: {
      type: [String, Number, Boolean],
      default: true
    }
  },
  methods: {
    filterChange(item) {
      this.$emit('change', item[this.code])
    }
  }
}
</script>
<style lang="less" scoped>
@blue: #0088FF;
.filter{
  display: flex;
  justify-content: space-between;
  // padding: 10px 0 0 20px;
  padding-left: 20px;
  align-items: center;
  .filter-l{
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
    .filter-l-item{
      margin-right: 8px;
      padding: 0 12px;
      border: 1px solid #E5E5E5;
      line-height: 22px;
      border-radius: 12px;
      background: #fff;
      white-space: nowrap;
      &.active {
        border-color: @blue;
        color: @blue;
        background: rgba(0, 136, 255, 0.11);
      }
    }
  }
  .filter-r{
    color: @blue;
    width: 70px;
    flex-shrink: 0;
    padding: 0 10px;
  }
}
</style>
