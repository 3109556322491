import ajax from '../../utils/ajax'
const gateway = '/activity-config'
export default {
  // http://81.69.154.237/access/activity-config/v1/activity/backend/activity_page/get
  getActivityPage(data) {
    return ajax({
      url: '/activity-config/v1/activity/client/activity/findActivityById',
      method: 'post',
      data
    })
  },
  // 发送短信验证码
  getSmsCode(data) {
    return ajax({
      url: gateway + '/v1/activity/client/iam/send-phone',
      method: 'post',
      data
    })
  },
  // 校验验证码
  checkSmsCode(data) {
    return ajax({
      url: gateway + '/v1/activity/client/iam/check-phone',
      method: 'post',
      data
    })
  },
  // 提交留资
  leaveInfo(data) {
    return ajax({
      url: '/activity-user/v1/activity/client/leave_information/page-leaveinfomation-add',
      method: 'post',
      headers: {
        companyId: 1
      },
      data
    })
  }
}
