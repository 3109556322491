<!--
 * @Author: ke.xue
 * @Date: 2021-12-17 11:24:06
 * @LastEditors: ke.xue
 * @LastEditTime: 2022-01-18 15:10:19
 * @FilePath: \qw-scrm-h5\components\clue\checkbox.vue
 * @Description: 自定义多选组件
-->
<template>
  <div class="clueCheckbox formBox">
    <h3 :class="['formLabel', { formRequired: formJson.required }]">{{ formJson.screenKey }}</h3>
    <div class="formTag">
      <div
        v-for="(item, index) in screenValue"
        :key="index"
        class="tag-box pointer"
      >
        <div class="tag-group">{{item.tagGroupName}}</div>
        <div class="tag-item">
          <el-tag v-for="(m) in item.tagList" :key='m.tagId' :effect="tagIds.indexOf(m.tagId)=== -1? 'plain':'dark'" @click="clickTag(m)">{{ m.tagName }}</el-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClueCheckbox',
  model: {
    prop: 'defaultValue',
    event: 'change'
  },
  props: {
    formJson: {
      type: Object,
      required: true,
      default: () => ({})
    },
    defaultValue: {
      type: [Array, Object],
      default: () => ([])
    }
  },
  data() {
    return {
      screenValue: [],
      valueKey: 'code',
      labelKey: 'name',
      currentObj: {},
      tagIds: [] // 选中标签的ids
    }
  },
  watch: {
    'formJson.screenValue': {
      deep: true,
      immediate: true,
      handler(nVal) {
        this.init()
        this.screenValue = nVal
        const activeObj = {}
        const { defaultValue, valueKey } = this
        if (defaultValue.length) {
          nVal.map(item => {
            const code = String(item[valueKey])
            defaultValue.includes(code) && (activeObj[code] = item)
          })
        }
        this.currentObj = activeObj
      }
    },
    defaultValue: {
      deep: true,
      immediate: true,
      handler(val) {
        this.tagIds = val.length > 0 ? val : []
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const { valueKey, labelKey } = this.formJson
      valueKey && (this.valueKey = valueKey)
      labelKey && (this.labelKey = labelKey)
    },
    onChange(item) {
      const { valueKey, currentObj } = this
      const code = item[valueKey]
      if (currentObj[code]) {
        this.$delete(this.currentObj, code)
      }
      else {
        this.$set(this.currentObj, code, item)
      }
      const active = Object.keys(this.currentObj)
      this.$emit('change', active)
    },
    clickTag(data) {
      if (this.tagIds.indexOf(data.tagId) === -1) {
        this.tagIds.push(data.tagId)
      }
      else {
        const index = this.tagIds.indexOf(data.tagId)
        this.tagIds.splice(index, 1)
      }
      this.$emit('change', this.tagIds)
    }
  }
}
</script>

<style lang="less" scoped>
.clueCheckbox {
  // .screenValueItem {
  //   border-radius: 2px;
  // }
  .formTag {
    margin-top: 10px;
    .tag-box{
      .tag-group{
        font-size: 16px;
        padding: 5px 0;
      }
      .tag-item{
        font-size: 14px;
        /deep/ .el-tag{
          margin-top: 4px;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
