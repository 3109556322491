<template>
  <div class="content">
    <div class="editform" style="width: 100%">
      <!-- 基本信息 -->
      <el-form ref="formData" label-position="right" :model="formData" label-width="100px" :rules="rules">
        <el-form-item label="SOP类型:" prop="sopType">
          <el-select v-model="formData.sopType" :disabled="!!editData.id || isDetail" placeholder="SOP类型" style="width: 300px">
            <el-option
v-for="item in [
                { value: 1, label: '客户运营' },
                { value: 2, label: '内容运营' },
                // { value: 3, label: '朋友圈' },
                { value: 4, label: '活动运营' },
              ]"
:key="item.value"
style="width: 300px"
:label="item.label"
:value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="SOP名称:" prop="name">
          <el-input v-model="formData.name" :disabled="isDetail" type="textarea" :maxlength="128" style="width: 300px" placeholder="SOP名称"></el-input>
        </el-form-item>
        <el-form-item label="SOP描述:" prop="description">
          <el-input v-model="formData.description" :disabled="isDetail" style="width: 300px" type="textarea" :maxlength="512" :rows="4" placeholder="SOP描述"></el-input>
        </el-form-item>
        <el-form-item label="执行周期:" prop="startEndTime">
          <el-date-picker v-model="formData.startEndTime" style="width: 300px" type="daterange" :disabled="isDetail" :picker-options="pickerOptions" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户群" style="width: 100%" prop="cusGroup">
          <span v-if="formData.chatGroupList.length">群名:</span>
          <span v-for="(item, index) in formData.chatGroupList" :key="item.id">
            <el-tag :key="index" type="success" style="margin: 0 5px">
              {{ item.name }}
            </el-tag>
          </span><br v-if="formData.chatGroupList.length" />
          <span v-if="formData.tags.length">群标签:</span>
          <span v-for='(item, index) in formData.tags' :key='index'>
            <span v-if="index!==0 && item.list.length" style='margin: 0 5px'><br/>且</span>
            <el-tag v-for='(t) in item.list' :key='t.tagId' type='success' style='margin: 0 5px'>
              {{ t.tagName }}
            </el-tag>
          </span>

          <div>
            <el-button type="primary" :disabled="isDetail" @click="tagsSet">客户群设置</el-button>
            <!-- <el-button v-if="formData.tagList.length > 0 && formData.tagList[0].list" type="primary" @click="tagsPreview">客户群试算 </el-button> -->
          </div>
        </el-form-item>
        <el-form-item label="执行者" prop="executor">
          <el-select v-model="formData.executor" :disabled="!!editData.id || isDetail" placeholder="请选择" style="width: 300px" @change="executorChange">
            <el-option v-for="item in executorList" :key="item.code" :label="item.name" :value="item.code"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送阶段">
          <div v-for="(item, index) in formData.sopSends" :key="index" class="itemIndivid">
            <el-form-item
:label="`阶段${index + 1}`"
:prop="'sopSends.' + index + '.stageStartDay'"
:rules="[
                { required: true, message: '请填写开始天数', trigger: 'change' },
                { validator: (rule, value, callback) => stageStartDay(rule, value, callback, index, item), message: '请检查开始天数小于结束天数，并且阶段递增', trigger: 'change' },
              ]">
              <span>SOP执行</span>
              <span style="margin: 0 5px">
                <el-input-number v-model="item.stageStartDay" :disabled="isDetail" :min="1" controls-position="right" :style="{ width: '100px' }" />
              </span>
              <span>至</span>
              <span style="margin: 0 5px">
                <el-input-number v-model="item.stageEndDay" :disabled="isDetail" :min="1" controls-position="right" :style="{ width: '100px' }" />
              </span>
              <span>天</span>
              <span>
                <el-button v-if="index === formData.sopSends.length - 1" :disabled="isDetail" style="padding-left: 10px" size="mini" type="text" @click="addStage">新增阶段</el-button>
                <el-button v-if="formData.sopSends.length - 1 === index && index" :disabled="isDetail" style="padding-left: 10px" size="mini" type="text" @click="deleteStage(index, item)">删除阶段</el-button>
              </span>
            </el-form-item>

            <el-form-item label="发送频次:" :prop="'sopSends.' + index + '.sendFrequency'" :rules="[{ required: true, message: '请选择频次', trigger: 'blur' }]">
              <el-radio-group v-model="item.sendFrequency" :disabled="isDetail">
                <el-radio
v-for="(rItem, rindex) in [
                    { label: '每天', value: 0 },
                    { label: '单次', value: 1 },
                  ]"
:key="rindex"
:label="rItem.value">{{ rItem.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="发送时间" :prop="'sopSends.' + index + '.sendTime'" :rules="[{ required: true, message: '请完善发送时间或者发送类型', trigger: 'change' }]">
              固定时间
              <el-time-picker v-model="item.sendTime" clearable :disabled="isDetail" size="small" placeholder="选择时间" value-format="HH:mm:ss" @change="sendTimeChange(index)"> </el-time-picker>
              <!-- <el-radio-group v-model="item.sendTimeType" :disabled="isDetail" style="margin-left: 10px" @change="sendTimeTypeChange(index)">
                <el-radio
v-for="(item, index) in [
                    { label: '上午随机', value: 1 },
                    { label: '下午随机', value: 2 },
                    { label: '晚上随机', value: 3 },
                  ]"
:key="index"
:label="item.value">{{ item.label }}
                </el-radio>
              </el-radio-group> -->
            </el-form-item>
            <el-form-item label="发送内容">
              <EnterpriseMdist :ref="`enter${index}`" :is-disabled="isDetail" :video-feel="true" :i-tem-array="item.sopContentList" :is-group="formData.executor==='owner'?true:false" :stage-index='index' @onChage="(val) => onChage(val, index)"></EnterpriseMdist>
            </el-form-item>
          </div>
          <!--          <el-form ref='indiviForm' label-position='right' :model='formData.sopSends' label-width='80px' :rules='rules'>-->
          <!--            <el-form-item label='发送内容'>-->
          <!--              <EnterpriseMdist ref='enter' @onChage='onChage'></EnterpriseMdist>-->
          <!--            </el-form-item>-->
          <!--          </el-form>-->
        </el-form-item>
      </el-form>

      <div
class="editformBtn"
:style="{
          position: 'absolute',
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 9,
          borderTop: '1px solid #e9e9e9',
          padding: '10px 20px',
          background: '#fff',
          textAlign: 'right',
        }">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" :disabled="isDetail" @click="saveDialog">保 存</el-button>
      </div>
    </div>
    <preview v-if="formData.reachType !== 'operate_guide_001'" style="margin-left: 10px" :send-content="sendContent"></preview>

    <BaseDialog :visible.sync="showAddNew" :title="dialogTitle" width="1000px" destroy-on-close :container-style="{ height: 'calc(100% - 60px)', overflow: 'auto', paddingBottom: '30px' }">
      <tagSet :tag-list="formData.tags" :chat-group-list="formData.chatGroupList" @close="closeHandle" @save="saveHandle"></tagSet>
    </BaseDialog>

    <Drawer :z-index="1999" :width="500" :title="drawer.title" mask-closable destroy-on-close :visible.sync="drawer.visible" @afterVisibleChange="drawHandleClose">
      <tagsPreview :tag-list="formData.tags" @close="drawCloseHandle" @save="drawSaveHandle"></tagsPreview>
    </Drawer>
  </div>
</template>

<script>
import Preview from '@/views/cusGroupOperateSop/components/preview.vue'
import tagSet from '@/views/cusGroupOperateSop/components/tagSet.vue'
import EnterpriseMdist from '@/views/cusGroupOperateSop/components/enterpriseMdist.vue'
// import { getdict } from '@/api/modules/cusGroupOperateSop.js'
import tagsPreview from '@/views/cusGroupOperateSop/components/tagsPreview.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'EditForm',
  components: {
    Preview,
    tagSet,
    EnterpriseMdist,
    tagsPreview
  },
  computed: {
    ...mapGetters('auth', ['getdeleteList', 'getdeleteRuleId'])
  },
  props: {
    editData: {
      type: Object,
      default: () => { }
    },
    isDetail: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      isAllowsToSave: false,
      showAddNew: false,
      dialogTitle: '客户群设置',
      drawer: {
        title: '',
        visible: false
      },
      executorList: [],
      sendContent: [],
      formData: {
        operatorWay: 2,
        name: '',
        startEndTime: [],
        description: '',
        executor: '',
        reachType: '',
        sopType: 1,
        startDate: '',
        endDate: '',
        chatGroupList: [],
        sopSends: [
          {
            stageStartDay: 1,
            stageEndDay: 1,
            sendFrequency: 1,
            sendTime: '',
            sendTimeType: null,
            sopContentList: [],
            get sendTimeValid() {
              return this.sendTime || this.sendTimeType
            }
          }
        ],
        tags: [],
        typeList: []
      },
      rules: {
        sopType: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        name: [{ required: true, message: '请填写SOP名称', trigger: 'blur' }],
        cusGroup: [
          // { required: true, message: '请选择客户群', trigger: 'change' },
          // { validator: this.tagListValidator, message: '请选择客户群', trigger: 'change' }
          { required: true, validator: this.tagListValidator, trigger: 'change' }
        ],
        description: [{ required: true, message: '请填写SOP描述', trigger: 'blur' }],
        startEndTime: [{ required: true, message: '请选择执行周期', trigger: 'blur' }],
        executor: [{ required: true, message: '请选择执行者', trigger: 'blur' }]
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000
        }
      },
      deleteRuleIdList: []
    }
  },
  watch: {
    'formData.startEndTime'(val) {
      this.formData.startDate = val[0]
      this.formData.endDate = val[1]
    }
  },
  created() {
    // 每次弹框打开，清空删除的id数组集合
    this.$store.dispatch('auth/setDeletList', [])
    this.$store.dispatch('auth/setDeletRuleList', [])
    this.getDictList()

    // 存在id 回显
    if (this.editData.id) {
      this.editData.startDate = this.editData.startDate || ''
      this.editData.endDate = this.editData.endDate || ''
      this.formData.startEndTime = [this.editData.startDate, this.editData.endDate]
      this.formData = { ...this.formData, ...this.editData }
      this.formData.chatGroupList = this.formData.chatGroupResponses || []

      this.formData.sopSends.map((item) => {
        item.sendTimeValid = item.sendTime || item.sendTimeType
      })
    }
  },

  methods: {
    stageStartDay(rule, value, callback, index, item) {
      const { stageStartDay = '', stageEndDay = '' } = item
      let status = true
      if (Number(stageStartDay) <= Number(stageEndDay)) {
        if (index > 0 && Number(this.formData.sopSends[index - 1].stageEndDay) >= Number(stageStartDay)) {
          status = false
        }
        if (this.formData.sopSends.length > 1 && this.formData.sopSends[index + 1] && Number(this.formData.sopSends[index + 1].stageStartDay) <= Number(stageEndDay)) {
          status = false
        }
      }
      else {
        status = false
      }
      if (status) {
        return callback()
      }
      else {
        return callback(new Error(''))
      }
    },
    sendTimehandle(rule, value, callback, index, item) {
      if (value || value === 0) {
        return callback()
      }
      else {
        return callback(new Error(''))
      }
    },
    tagListValidator(rule, value, callback) {
      if (this.formData.tags.length === 0 && this.formData.chatGroupList.length === 0) {
        callback(new Error('请选择客户群或群标签'))
      }
      else {
        callback()
      }
    },
    // 返回
    closeDialog(type) {
      this.$emit('close', type)
    },
    async saveDialog() {
      const { chatGroupList, ...params } = this.formData
      const arr = []
      chatGroupList.map(item => {
        arr.push({ id: item.id })
      })
      await this.$refs.formData.validate(async(vald) => {
        if (vald) {
          let valid = true
          valid = await this.CHECK_REQUIRED()
          if (!valid) {
            return
          }
          else {
            this.$emit('save', {
              // ...this.formData,
              chatGroupList: arr,
              ...params,
              loading: this.loading,
              deleteContentIdList: this.getdeleteList,
              deleteRuleIdList: this.getdeleteRuleId
            })
          }
        }
        else {
          this.$message.error('请完善必填信息')
        }
      })
    },
    CHECK_REQUIRED() {
      let num = 0
      return new Promise((resolve) => {
        this.formData.sopSends.forEach((res, index) => {
          this.$nextTick(() => {
            this.$refs[`enter${index}`][0].CHECK_REQUIRED().then((res) => {
              if (res) {
                num++
                if (num === this.formData.sopSends.length) {
                  resolve(res)
                }
              }
            })
          })
        })
      })
    },
    tagsSet() {
      console.log('标签设置')
      this.showAddNew = true
    },
    tagsPreview() {
      console.log('标签试算')
      this.drawer.title = '标签试算'
      this.drawer.visible = true
    },
    closeHandle() {
      this.showAddNew = false
    },
    saveHandle(list, groupList) {
      this.showAddNew = false
      this.formData.chatGroupList = groupList
      this.formData.tags = list
    },
    executorChange() { },
    onChage(val, index) {
      console.log(val, index)
      const list = []
      val.forEach((item) => {
        list.push({ ...item.data })
      })

      this.formData.sopSends[index].sopContentList = list
      this.preview(this.formData.sopSends)
    },

    preview(val) {
      this.sendContent = []
      const conf = {
        content_type_001: 'text',
        content_type_002: 'image',
        content_type_003: 'news',
        content_type_004: 'miniprogram_notice',
        content_type_005: 'video',
        content_type_006: 'file'
      }

      const previewList = []
      val.forEach((item) => {
        item.sopContentList.forEach((cItem) => previewList.push(cItem))
      })
      previewList.forEach((x, index) => {
        const str = {
          msgtype: `${conf[x.contentType]}`,
          content: x.content,
          imgUrl: x.imgUrl,
          title: x.title,
          description: x.desc,
          logo: x.imgUrl,
          appname: x.title,
          picUrl: x.videoUrl,
          fileName: x.fileName
        }
        if (this.sendContent[index]?.msgtype) {
          this.$set(this.sendContent, index, str)
        }
        else {
          this.sendContent.push(str)
        }
      })
    },
    async getDictList() {
      // const data = await getdict({ type: 'executor' })
      const list = [{
        code: 'owner',
        deleted: 0,
        id: 71,
        name: '群主',
        parentId: 0,
        parentIds: '0',
        status: 0,
        type: 'executor'
      }]
      this.executorList = list
    },
    drawHandleClose(val) {
      console.log('当前显示状态：', val)
    },
    drawCloseHandle() {
      this.drawer.visible = false
    },
    drawSaveHandle() {
      this.drawer.visible = false
    },
    async addStage() {
      this.formData.sopSends.push({
        stageStartDay: 1,
        stageEndDay: 1,
        sendFrequency: 1,
        sendTime: '',
        sendTimeType: null,
        sopContentList: [],
        get sendTimeValid() {
          return this.sendTime || this.sendTimeType
        }
      })
    },
    deleteStage(index, item) {
      this.formData.sopSends.splice(index, 1)
      this.deleteRuleIdList.push(item.ruleId)
      this.$store.dispatch('auth/setDeletRuleList', this.deleteRuleIdList)
    },
    sendTimeChange(index) {
      this.formData.sopSends[index].sendTimeType = null
    },
    sendTimeTypeChange(index) {
      this.formData.sopSends[index].sendTime = ''
    }
  }
}
</script>

<style lang='less' scoped>
.editformBtn {
  text-align: right;
}

.content {
  display: flex;
}

@deep: ~'>>>';
.itemIndivid {
  @{deep} .el-form-item {
    padding-bottom: 20px;

    .el-form-item__label {
      text-align: left;
    }
  }
}
</style>
